import { useQuery } from 'react-query';

export function useAaroQuery(queryKey, apiFunction, options = {}) {
    const defaultOptions = {
        refetchOnWindowFocus: false,
        staleTime: 300000, // 5 dakika
        retry: 2,
        ...options,
    };

    return useQuery(
        queryKey,
        async () => {
            const response = await apiFunction();

            if (!response.data?.Sonuc && options.requireSuccess) {
                throw new Error(response.data?.Mesajlar?.Mesaj || 'Veri çekme hatası');
            }
            return response.data?.Model || response.data;
        },
        defaultOptions
    );
}
