// Libraries
import { useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import MDBox from 'components/MDBox';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import ErrorMessage from 'components/Common/ErrorMessage';
import OrderLayout from 'layouts/orders/pages/SingleOrder/Layout';

// Page Components
import DetailsPage from './Sections/DetailsPage';
import SalesDetailPage from './Sections/SalesDetailPage';
import OrderSummary from './Sections/OrderSummary';
// Hooks
import { useOrderQueries } from 'hooks/aaro/useOrderQueries';
import { useContractQueries } from 'hooks/aaro/useContractQueries';
// MUI
import Grid from '@mui/material/Grid';

function SingleOrder() {
    const { id } = useParams();
    const [value, setValue] = useState(0);

    const orderQueries = useOrderQueries({ id: id, type: 'DekontID', TabloID: 'DekontFatura', TabloSatirID: id });
    const contractQueries = useContractQueries(id);

    // Combine all queries for error and loading states
    const allQueries = { ...orderQueries, ...contractQueries };
    const isLoading = Object.values(allQueries).some((query) => query?.isLoading);
    const error = Object.values(allQueries).find((query) => query?.error)?.error;

    const pageContent = {
        0: <DetailsPage queries={allQueries} orderID={id} />,
        1: <SalesDetailPage queries={allQueries} />,
    };

    if (isLoading) return <LoadingSpinner />;
    if (error) return <ErrorMessage error={error} />;

    return (
        <OrderLayout setValue={setValue}>
            <MDBox mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        {pageContent[value]}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <OrderSummary queries={allQueries} orderID={id} />
                    </Grid>
                </Grid>
            </MDBox>
        </OrderLayout>
    );
}

export default SingleOrder;
