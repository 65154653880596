import { useMemo } from 'react';
import { useAaroQuery } from './useAaroQuery';
import {
    getOrderProductMovements,
    getOrders,
    getOrdersDetailed,
    getNotesBasic,
} from 'services/outside/aaro/aaroDekont';

export function useOrderQueries(params, options = {}) {
    const { id, type = 'DekontID', pageSize = 100, page = 1 } = params;

    const orderMovementsQuery = useAaroQuery(
        ['orderMovements', id, type, page, pageSize],
        () =>
            getOrderProductMovements({
                [type]: id,
                SayfaSatirSayisi: pageSize,
                SayfaNo: page,
            }),
        { ...options, requireSuccess: true }
    );

    const uniqueDekontIds = useMemo(() => {
        if (!orderMovementsQuery.data) return [];
        return [...new Set(orderMovementsQuery.data.map((item) => item.DekontID))];
    }, [orderMovementsQuery.data]);

    const orderDetailsQuery = useAaroQuery(
        ['orderDetails', uniqueDekontIds, page, pageSize],
        () =>
            getOrders({
                DekontID: uniqueDekontIds.join(','),
                SayfaSatirSayisi: pageSize,
                SayfaNo: page,
            }),
        { ...options, enabled: uniqueDekontIds.length > 0 }
    );

    const orderItemsQuery = useAaroQuery(
        ['orderItems', id, type, page, pageSize],
        () =>
            getOrdersDetailed({
                [type]: id,
                SayfaSatirSayisi: pageSize,
                SayfaNo: page,
            }),
        { ...options, requireSuccess: true }
    );

    const notesQuery = useAaroQuery(
        ['orderNotes', id, type],
        () => getNotesBasic({ TabloID: params.TabloID, TabloSatirID: params.TabloSatirID }),
        {
            ...options,
            requireSuccess: true,
        }
    );

    return {
        orderMovementsQuery,
        orderDetailsQuery,
        orderItemsQuery,
        uniqueDekontIds,
        notesQuery,
    };
}
