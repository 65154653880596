import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { getContracts } from 'services/outside/aaro/aaroDekont';
import MDButton from 'components/MDButton';

const ContractsFilterForm = forwardRef(({ onDataFetched }, ref) => {
    const [submitTime, setSubmitTime] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: {
            Tarih: '',
            TipAdi: '',
            EsnekAramaKisiti: '',
            Sayfa: 1,
            SayfaSatirSayisi: 10,
            SiralamaKisiti: 'Tarih:Desc',
        },
        onSubmit: (values) => {
            mutation.mutate(values);
        },
    });

    useImperativeHandle(ref, () => ({
        setFieldValue: formik.setFieldValue,
        submitForm: formik.submitForm,
    }));

    const mutation = useMutation(
        (values) => {
            const mappedValues = {
                Tarih: values.Tarih || '',
                TipAdi: values.TipAdi || '',
                EsnekAramaKisiti: values.EsnekAramaKisiti || '',
                Sayfa: values.Sayfa,
                SayfaSatirSayisi: values.SayfaSatirSayisi,
                SiralamaKisiti: 'Tarih:Desc',
            };

            const filteredValues = Object.entries(mappedValues).reduce((acc, [key, value]) => {
                if (value !== undefined && value !== '') acc[key] = value;
                return acc;
            }, {});

            return getContracts(filteredValues);
        },
        {
            onMutate: () => {
                setIsSubmitting(true);
                setSubmitTime(0);
                const startTime = new Date().getTime();
                return startTime;
            },
            onSuccess: (data, variables, startTime) => {
                if (data.data.Sonuc) {
                    onDataFetched(data.data.Model, data.data.SayfalandirmaBilgisi);
                }
                const endTime = new Date().getTime();
                setSubmitTime(endTime - startTime);
            },
            onSettled: () => {
                setIsSubmitting(false);
            },
        }
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id="Tarih"
                        name="Tarih"
                        label="Tarih"
                        type="date"
                        value={formik.values.Tarih}
                        onChange={formik.handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id="EsnekAramaKisiti"
                        name="EsnekAramaKisiti"
                        label="Arama"
                        value={formik.values.EsnekAramaKisiti}
                        onChange={formik.handleChange}
                        placeholder="Sözleşme kodu, cari kodu veya cari adı ile arama yapın"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id="TipAdi"
                        name="TipAdi"
                        label="Tip"
                        select
                        value={formik.values.TipAdi}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value="">Seçiniz</MenuItem>
                        <MenuItem value="Alis">Alış</MenuItem>
                        <MenuItem value="Satis">Satış</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <MDButton type="submit" color="info" variant="contained" disabled={isSubmitting}>
                            Filtrele
                        </MDButton>
                        {submitTime > 0 && (
                            <span style={{ marginLeft: '1rem' }}>Sorgu {submitTime / 1000} saniye sürdü.</span>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
});

ContractsFilterForm.displayName = 'ContractsFilterForm';

export default ContractsFilterForm;
