import MDBox from 'components/MDBox';
import MDProgress from 'components/MDProgress';
import MDTypography from 'components/MDTypography';

function LoadingSpinner({ message = 'Yükleniyor...' }) {
    return (
        <MDBox display="flex" flexDirection="column" alignItems="center" mt={2}>
            <MDProgress variant="gradient" value={0} />
            <MDTypography variant="button" color="text" mt={1}>
                {message}
            </MDTypography>
        </MDBox>
    );
}

export default LoadingSpinner;
