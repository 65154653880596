import React, { memo } from 'react';
import { DataGridPro, GridFooterContainer } from '@mui/x-data-grid-pro';
import { Typography, Chip, Icon, Box } from '@mui/material';

const TransactionTypeChip = memo(({ TipAdi }) => {
    const getTypeProperties = (tip) => {
        const actualType = tip.split(' - ')[0];

        switch (actualType) {
            case 'Çek Alındı':
                return { color: 'warning', icon: 'receipt_long' };
            case 'POS Tahsilat':
                return { color: 'info', icon: 'payment' };
            case 'Havale/EFT Alma':
                return { color: 'success', icon: 'payments' };
            default:
                return { color: 'default', icon: 'account_balance_wallet' };
        }
    };

    const { color, icon } = getTypeProperties(TipAdi);
    return <Chip label={TipAdi} color={color} icon={<Icon>{icon}</Icon>} variant="outlined" size="small" />;
});

const DaysChip = memo(({ value, dekontId }) => {
    const bugun = new Date();
    const farkGun = Math.floor((bugun - new Date(value)) / (1000 * 60 * 60 * 24));

    return (
        <a
            href={`https://erp2.aaro.com.tr/DekontFatura/Kalem?id=${dekontId}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
        >
            <Chip label={`${farkGun} gün`} color="default" variant="outlined" size="small" clickable />
        </a>
    );
});

function CustomerMovements({ queries }) {
    const { movementsQuery } = queries;

    const columns = [
        {
            field: 'Tarih',
            headerName: 'Gün',
            flex: 0.15,
            renderCell: (params) => <DaysChip value={params.value} dekontId={params.row.DekontID} />,
        },
        {
            field: 'TipAdi',
            headerName: 'İşlem Tipi',
            flex: 0.3,
            renderCell: (params) => <TransactionTypeChip TipAdi={params.value} />,
        },
        { field: 'BA', headerName: 'B/A', flex: 0.1 },
        { field: 'BelgeNo', headerName: 'Belge No', flex: 0.3 },
        { field: 'Aciklama', headerName: 'Açıklama', flex: 0.3 },
        {
            field: 'OlsAdi',
            headerName: 'İşlem Yapan',
            flex: 0.25,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
        },
        {
            field: 'Tutar',
            headerName: 'Tutar',
            flex: 0.4,
            valueFormatter: (params) => {
                if (!params?.value) return '';
                const currency = params?.row?.DovizKodu === 'TRY' ? '₺' : params?.row?.DovizKodu || '₺';
                return `${new Intl.NumberFormat('tr-TR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(params.value)} ${currency}`;
            },
        },
    ];

    const footerSummary = React.useMemo(() => {
        if (!movementsQuery.data) return [];

        const summaryByType = movementsQuery.data.reduce((acc, curr) => {
            if (!acc[curr.TipAdi]) {
                acc[curr.TipAdi] = {
                    total: 0,
                    currency: curr.DovizKodu || 'TRY',
                    tipAdi: curr.TipAdi,
                };
            }
            acc[curr.TipAdi].total += curr.Tutar;
            return acc;
        }, {});

        return Object.entries(summaryByType).map(([tipAdi, { total, currency }]) => ({
            id: tipAdi,
            label: `${tipAdi} - ${new Intl.NumberFormat('tr-TR', {
                style: 'currency',
                currency: currency,
            }).format(total)}`,
        }));
    }, [movementsQuery.data]);

    if (movementsQuery.isError) {
        return <Typography color="error">Hata: {movementsQuery.error.message}</Typography>;
    }

    return (
        <DataGridPro
            rows={(movementsQuery.data || []).filter(
                (row) =>
                    ![
                        5004, 5008, 5009, 10005, 10006, 10007, 10008, 10009, 10010, 10011, 10012, 10013, 10014, 10015,
                        10016, 10019,
                    ].includes(row.TipID)
            )}
            columns={columns}
            autoHeight
            getRowId={(row) => row.ID || Math.random()}
            loading={movementsQuery.isLoading}
            initialState={{
                pinnedColumns: {
                    left: ['Tarih'],
                },
            }}
            components={{
                Footer: () => (
                    <GridFooterContainer>
                        <Box sx={{ p: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {footerSummary.map(({ id, label }) => (
                                <TransactionTypeChip key={id} TipAdi={label} />
                            ))}
                        </Box>
                    </GridFooterContainer>
                ),
            }}
            componentsProps={{
                footer: {
                    sx: { borderTop: 1, borderColor: 'divider' },
                },
            }}
        />
    );
}

export default CustomerMovements;
