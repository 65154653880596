import { Aaro } from 'aaro';
import { config } from 'Constants';
import { getAllData } from 'aaro-scripts';

export const getDekontKalem = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('Dekont/Kalemler', data);
};

export const getDekontBaslik = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('Dekont/Basliklar', data);
};

export const postDekontBaslik = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.post('Dekont/Baslik', data);
};

export const postDekontKalem = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.post('Dekont/Kalem', data);
};

export const getOrders = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('AlinanSiparisListe', data);
};

export const getNotesBasic = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('Notlar', data);
};

export const getOrdersDetailed = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('AlinanSiparisHareketleri', data);
};

export const getTodos = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('Gorevler', data);
};

export const getContracts = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('Sozlesme', data);
};

export const getCustomerMovements = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('CariHareketleri', data);
};

export const getProductMovements = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('StokHareketleri', data);
};

export const getOrderProductMovements = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('SipStokHareketleri', data);
};

/**
 * For given id, it returns all the notes
 * Returning array size could change
 */
export const getNotes = async (TabloID, TabloSatirID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    const cari = await getAllData(aaro, 'Notlar', {
        TabloID,
        TabloSatirID,
    });
    return cari;
};

export const getStocks = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('Stok', data);
};
export const getStocksSummary = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('StokOzet', data);
};
