import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Icon from '@mui/material/Icon';

function ErrorMessage({ error }) {
    return (
        <MDBox display="flex" alignItems="center" mt={2} p={2} bgcolor="error.light" borderRadius="lg">
            <Icon color="error" fontSize="medium" sx={{ mr: 1 }}>
                error
            </Icon>
            <MDTypography variant="button" color="error" fontWeight="medium">
                {error?.message || 'Bir hata oluştu'}
            </MDTypography>
        </MDBox>
    );
}

export default ErrorMessage;
