import React, { useContext, useRef } from 'react';
import { BasketContext } from 'layouts/aaro/fastPrice';
import Icon from '@mui/material/Icon';

import { DataGridPro, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';
import pxToRem from 'assets/theme/functions/pxToRem.js';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import AaroButtons from '../aaroButtons';
import { formatPrice, calculateTaxPrice, calculateProfit, calculateDiscount } from 'helpers/priceHelpers';
import MDButton from 'components/MDButton';
import TextField from '@mui/material/TextField';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Basket(props) {
    const { basket, setBasket } = useContext(BasketContext);
    const [isBulkEdit, setIsBulkEdit] = React.useState(false);
    const amountRef = useRef(null);
    const discountRef = useRef(null);
    const taxRef = useRef(null);

    const apiRef = useGridApiRef();

    const severityDecider = (discount, tax) => {
        const profit = calculateProfit(discount, tax);
        if (profit >= 81) return ['success', 'İyi'];
        if (profit < 81 && profit >= 60) return ['info', 'Normal'];
        if (profit < 60 && profit >= 40) return ['warning', 'Düşük'];
        if (profit < 40) return ['error', 'Dip'];
    };
    const columns = [
        {
            field: 'edit',
            headerName: 'Sil',
            flex: 0.3,
            renderCell: (params) => (
                <>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                            const currentBasket = JSON.parse(JSON.stringify(basket));
                            for (let i = 0; i < currentBasket.length; i++) {
                                if (currentBasket[i].StokID === params.id) {
                                    currentBasket.splice(i, 1);
                                }
                            }
                            setBasket(currentBasket);
                        }}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            ),
            editable: false,
        },
        {
            field: 'profit',
            headerName: 'Kar',
            flex: 1,
            renderCell: (params) => (
                <Alert
                    severity={severityDecider(params.row.Discount, params.row.Tax)[0]}
                    style={{ width: '110px', color: 'white' }}
                >
                    {severityDecider(params.row.Discount, params.row.Tax)[1]}
                </Alert>
            ),
            editable: false,
        },
        //     <BorderLinearProgress
        //     variant="determinate"
        //     value={profitValue}
        // />
        { field: 'StokID', headerName: 'StokID', flex: 0.5, hide: true },
        { field: 'StokKodu', headerName: 'Stok Kodu', flex: 1.5, hide: true },
        { field: 'StokAdi', headerName: 'Stok Adı', flex: 2.5 },
        {
            field: 'Price',
            headerName: 'Birim Fiyat',
            flex: 1,
            type: 'number',
            renderCell: (params) => formatPrice(calculateDiscount(params.row.Price, 1, params.row.Discount)),

            editable: true,
        },
        {
            field: 'Amount',
            headerName: 'Miktar',
            flex: 1,
            type: 'number',
            editable: true,
        },

        {
            field: 'Discount',
            headerName: 'İskonto',
            flex: 1,
            type: 'number',
            renderCell: (params) => {
                const valueFormatted = Number(params.value).toLocaleString();
                return `${valueFormatted} %`;
            },

            editable: true,
        },
        {
            field: 'Tax',
            headerName: 'Vergi',
            flex: 1,
            type: 'number',
            editable: true,
        },

        {
            field: 'Total',
            headerName: 'Toplam',
            flex: 1,
            type: 'number',
            renderCell: (params) => {
                const valueFormatted = calculateTaxPrice(
                    calculateDiscount(params.row.Price, params.row.Amount, params.row.Discount),
                    params.row.Tax
                );
                return `${formatPrice(valueFormatted)}`;
            },
        },
    ];

    const SumOfGridRows = () => {
        const rows = apiRef.current.getVisibleRowModels();
        let total = 0;

        for (const cur of rows.values()) {
            total += calculateTaxPrice(calculateDiscount(cur.Price, cur.Amount, cur.Discount), cur.Tax);
        }
        return formatPrice(total, 'TRY');
    };
    const handleRowEditCommit = React.useCallback(
        (params) => {
            console.log(params);
            const currentBasket = JSON.parse(JSON.stringify(basket));
            console.log(currentBasket);

            for (let i = 0; i < currentBasket.length; i++) {
                if (currentBasket[i].id === params.id) {
                    const temp = JSON.parse(JSON.stringify(currentBasket[i]));
                    const key = params.field;
                    temp[key] = params.value;
                    currentBasket[i] = temp;
                }
            }
            setBasket(currentBasket);
        },
        [basket]
    );

    const handleBulkUpdate = (field, ref) => {
        const currentBasket = JSON.parse(JSON.stringify(basket));
        currentBasket.forEach((item) => {
            item[field] = Number(ref.current.value);
        });
        setBasket(currentBasket);
    };

    return (
        <MDBox
            mx={3}
            my={2}
            color="white"
            bgColor="white"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            // sx={{ height: '400px', width: '%100' }}
        >
            {basket?.length > 0 && (
                <DataGridPro
                    autoHeight
                    rowHeight={26}
                    rows={basket}
                    apiRef={apiRef}
                    onCellEditCommit={handleRowEditCommit}
                    columns={columns}
                    initialState={{
                        pinnedColumns: { right: ['Total'] },
                    }}
                    sx={{
                        fontSize: pxToRem(12),
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1.25,
                    }}
                    components={{
                        Toolbar: GridToolbar,

                        Footer: () => (
                            <>
                                <MDBox
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    p={1}
                                >
                                    <MDBox display="flex" gap={2} alignItems="center">
                                        <AaroButtons />
                                        <MDBox pb={2}>
                                            <MDBox>
                                                <MDButton
                                                    variant={isBulkEdit ? 'contained' : 'outlined'}
                                                    color="dark"
                                                    size="small"
                                                    iconOnly
                                                    onClick={() => setIsBulkEdit(!isBulkEdit)}
                                                >
                                                    {isBulkEdit ? <Icon>remove</Icon> : <Icon>add</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                        {isBulkEdit && (
                                            <>
                                                <MDBox display="flex" gap={1} alignItems="center">
                                                    <TextField
                                                        inputRef={amountRef}
                                                        type="number"
                                                        label="Miktar"
                                                        defaultValue={1}
                                                        size="small"
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*',
                                                            min: 0,
                                                            step: '1',
                                                        }}
                                                        sx={{
                                                            width: '80px',
                                                            '& .MuiOutlinedInput-root': {
                                                                height: '35px',
                                                            },
                                                        }}
                                                    />
                                                    <MDButton
                                                        variant="contained"
                                                        color="success"
                                                        size="small"
                                                        onClick={() => handleBulkUpdate('Amount', amountRef)}
                                                    >
                                                        Uygula
                                                    </MDButton>
                                                </MDBox>

                                                <MDBox display="flex" gap={1} alignItems="center">
                                                    <TextField
                                                        inputRef={discountRef}
                                                        type="number"
                                                        label="İskonto"
                                                        defaultValue={0}
                                                        size="small"
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*',
                                                            min: 0,
                                                            max: 100,
                                                        }}
                                                        sx={{
                                                            width: '80px',
                                                            '& .MuiOutlinedInput-root': {
                                                                height: '35px',
                                                            },
                                                        }}
                                                    />
                                                    <MDButton
                                                        variant="contained"
                                                        color="success"
                                                        size="small"
                                                        onClick={() => handleBulkUpdate('Discount', discountRef)}
                                                    >
                                                        Uygula
                                                    </MDButton>
                                                </MDBox>

                                                <MDBox display="flex" gap={1} alignItems="center">
                                                    <TextField
                                                        inputRef={taxRef}
                                                        type="number"
                                                        label="Vergi"
                                                        defaultValue={20}
                                                        size="small"
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*',
                                                            min: 0,
                                                            max: 100,
                                                        }}
                                                        sx={{
                                                            width: '80px',
                                                            '& .MuiOutlinedInput-root': {
                                                                height: '35px',
                                                            },
                                                        }}
                                                    />
                                                    <MDButton
                                                        variant="contained"
                                                        color="success"
                                                        size="small"
                                                        onClick={() => handleBulkUpdate('Tax', taxRef)}
                                                    >
                                                        Uygula
                                                    </MDButton>
                                                </MDBox>
                                            </>
                                        )}
                                    </MDBox>
                                    <MDTypography variant="h6">Toplam: {SumOfGridRows()}</MDTypography>
                                </MDBox>
                                {/* <ExportToAaro basketChange={randomNumber} /> */}
                            </>
                        ),
                    }}
                />
            )}
        </MDBox>
    );
}

// const prepareRows = (list1, list2) => {
//     const rows = [];
//     list1.map((item) => {
//         rows.push({
//             stokID: item.StokID,
//             id: item.StrID,
//             kodu: item.StokKodu,
//             adi: item.StokAdi,
//             fiyatEski: findPair(item.StokKodu, list2)?.Fiyat,
//             fiyatYeni: item.Fiyat,
//         });
//     });
//     return rows;
// };

// const findPair = (stokKodu, list2) => {
//     const items = list2.filter((item) => item.StokKodu == stokKodu);
//     return items[0];
// };
