import React from 'react';
import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDProgress from 'components/MDProgress';
// Material Dashboard 2 PRO React context
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

// ChartJS kayıtları
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function ContractDetail({ queries }) {
    const {
        orderMovementsQuery,
        orderDetailsQuery,
        ordersDetailedQuery,
        contractQuery,
        movementsQuery,
        productMovementsQuery,
    } = queries;

    // Para formatlama fonksiyonu
    const formatCurrency = (amount, contract) => {
        return new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: contract.DovizKodu || 'TRY',
        }).format(amount);
    };

    // Loading / Error Durumu
    if (contractQuery.isLoading || movementsQuery.isLoading || productMovementsQuery.isLoading) {
        return <MDProgress variant="gradient" />;
    }
    if (contractQuery.isError) {
        return <MDTypography color="error">Hata: {contractQuery.error.message}</MDTypography>;
    }
    if (movementsQuery.isError) {
        return <MDTypography color="error">Hata: {movementsQuery.error.message}</MDTypography>;
    }
    if (productMovementsQuery.isError) {
        return <MDTypography color="error">Hata: {productMovementsQuery.error.message}</MDTypography>;
    }

    // Veriler
    const contract = contractQuery.data;

    // İstenmeyen hareket tiplerini hariç tut
    const filteredMovements = (movementsQuery.data || []).filter(
        (row) =>
            ![
                5004, 5008, 5009, 10005, 10006, 10007, 10008, 10009, 10010, 10011, 10012, 10013, 10014, 10015, 10016,
                10019,
            ].includes(row.TipID)
    );

    // Toplam tahsilat
    const totalCollected = filteredMovements.reduce((sum, movement) => sum + movement.Tutar, 0);

    // Satış Faturası tutarı
    const satisFaturasiTutari =
        productMovementsQuery.data
            ?.filter((movement) => movement.TipAdi === 'Satış Faturası')
            .reduce((sum, movement) => sum + movement.Tutar, 0) || 0;

    // Sipariş detayları
    const orderDetails = orderDetailsQuery.data || [];
    // Toplam sipariş tutarı
    const toplamSiparisTutari = orderDetails.reduce((sum, detail) => sum + (detail.Tutar || 0), 0);

    // Üretim tutarı
    const uretimTutari = orderDetails.reduce((sum, detail) => {
        const uretimOrani = detail.UretimOrani || 0;
        const detayTutari = detail.Tutar || 0;
        return sum + detayTutari * (uretimOrani / 100);
    }, 0);

    // Sevkiyat tutarı
    const sevkiyatTutari = orderDetails.reduce((sum, detail) => {
        const sevkiyatOrani = detail.SevkiyatOrani || 0;
        const detayTutari = detail.Tutar || 0;
        return sum + detayTutari * (sevkiyatOrani / 100);
    }, 0);

    // "Siparişe göre kesilmesi gereken fatura tutarı"
    // (Örnek: totalCollected - satisFaturasiTutari)
    const kalanFaturaTutari = totalCollected - satisFaturasiTutari;

    // Bar Chart Ayarları
    const barChartData = {
        labels: ['Sözleşme Tutarı', 'Sipariş Tutarı', 'Tahsilat', 'Fatura Edilen (KDVsiz)'],
        datasets: [
            {
                label: 'Tutar',
                data: [contract.Tutar, toplamSiparisTutari, totalCollected, satisFaturasiTutari],
                backgroundColor: [
                    '#1A73E8', // Primary blue
                    '#344767', // Keep same blue for consistency
                    '#4CAF50', // Success green only for collected amount
                    '#fb8c00', // Warning orange only for invoice amount
                ],
            },
        ],
    };

    const barChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Tek dataset olduğundan legend gizli olabilir
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const value = context.parsed.y;
                        return formatCurrency(value, contract);
                    },
                },
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        return formatCurrency(value, contract);
                    },
                },
            },
        },
    };

    // Üretim ve Sevkiyat İlerleme Oranları (Progress Bars)
    const productionRatio = toplamSiparisTutari ? (uretimTutari / toplamSiparisTutari) * 100 : 0;
    const shipmentRatio = toplamSiparisTutari ? (sevkiyatTutari / toplamSiparisTutari) * 100 : 0;

    // Progress bar color helper function
    const getProgressColor = (value) => {
        if (value < 30) return 'error';
        if (value < 70) return 'warning';
        return 'success';
    };

    return (
        <MDBox>
            <Card>
                <CardContent>
                    {/* Contract Summary Info */}
                    <MDBox mb={3} lineHeight={1.5}>
                        <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            component="a"
                            href={`https://erp2.aaro.com.tr/Sozlesme/Kalem?id=${contract.SozlesmeID}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                textDecoration: 'none',
                                color: 'inherit',
                                '&:hover': {
                                    color: 'primary.main',
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {contract.SozlesmeAdi?.toUpperCase()}
                        </MDTypography>
                        <br />
                        <MDTypography variant="caption" fontWeight="text" lineHeight={0}>
                            {contract.CariAdi?.toUpperCase()}
                        </MDTypography>
                        <br />
                        <MDTypography variant="caption" fontWeight="text" lineHeight={0}>
                            Sözleşme Tutarı: {formatCurrency(contract.Tutar, contract)}
                        </MDTypography>
                        <br />
                        <MDTypography variant="caption" fontWeight="text" lineHeight={0}>
                            Oluşturma: {new Date(contract.Tarih).toLocaleDateString('tr-TR')}(
                            {Math.floor((new Date() - new Date(contract.Tarih)) / (1000 * 60 * 60 * 24))} gün önce
                            {contract.OlsAdi ? ` ${contract.OlsAdi.toLowerCase()} tarafından` : ''})
                        </MDTypography>
                    </MDBox>
                </CardContent>
            </Card>

            {/* Chart Section */}
            <Card sx={{ mt: 3 }}>
                {' '}
                {/* mt: 3 adds margin-top for spacing */}
                <CardContent>
                    <MDBox mr={3} my={3} lineHeight={1.5}>
                        <MDTypography variant="h6" fontWeight="text">
                            Genel Özet
                        </MDTypography>
                    </MDBox>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MDBox>
                                <Bar data={barChartData} options={barChartOptions} />
                            </MDBox>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {/* Progress Bars in separate card */}
            <Card sx={{ mt: 3 }}>
                {' '}
                {/* mt: 3 adds margin-top for spacing */}
                <CardContent>
                    <MDBox mr={3} my={3} lineHeight={1.5}>
                        <MDTypography variant="h6" fontWeight="text">
                            Üretim Özet
                        </MDTypography>
                    </MDBox>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <MDBox textAlign="center">
                                <MDProgress
                                    value={Number(productionRatio).toFixed(1)}
                                    variant="gradient"
                                    color={getProgressColor(productionRatio)}
                                    label
                                />
                                <MDTypography variant="caption">
                                    Üretim: {formatCurrency(uretimTutari, contract)}
                                </MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xs={6}>
                            <MDBox textAlign="center">
                                <MDProgress
                                    value={Number(shipmentRatio).toFixed(1)}
                                    variant="gradient"
                                    color={getProgressColor(shipmentRatio)}
                                    label
                                />
                                <MDTypography variant="caption">
                                    Sevkiyat: {formatCurrency(sevkiyatTutari, contract)}
                                </MDTypography>
                            </MDBox>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </MDBox>
    );
}

export default ContractDetail;
