import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';

function TimePassedCard({ date }) {
    const calculateTimePassed = (date) => {
        if (!date) return 0;
        const currentDate = new Date();
        const orderDate = new Date(date);
        const timeDiff = currentDate.getTime() - orderDate.getTime();
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    };

    return (
        <MDBox mb={3}>
            <MiniStatisticsCard
                title={{ text: 'Geçen Süre' }}
                count={`${calculateTimePassed(date)} Gün`}
                icon={{
                    color: 'info',
                    component: 'gpp_maybe',
                }}
                direction="left"
            />
        </MDBox>
    );
}

TimePassedCard.propTypes = {
    date: PropTypes.string.isRequired,
};

export default TimePassedCard;
