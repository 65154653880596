import { useAaroQuery } from './useAaroQuery';
import { getTodos } from 'services/outside/aaro/aaroDekont';

export function useTodoQueries(params, options = {}) {
    const todosQuery = useAaroQuery(
        ['gorevler', ...Object.values(params)],
        () =>
            getTodos({
                ...params,
            }),
        { ...options, requireSuccess: true }
    );

    return {
        todosQuery,
    };
}
