import axios from 'axios';
import { config } from 'Constants';
/**
 * @desc for given id, it retrieves product in aaro
 * @return promise - axios returns a promise
 */
export const getAaroProduct = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/erp/aaro/products/${id}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given id, it retrieves product movements in aaro
 * @param page this is for pagination
 * @return promise - axios returns a promise
 */
export const getProductMovement = (id, page) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/erp/aaro/products/movement/${id}?page=${page || 1}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given id, it retrieves product price in aaro
 * @param {int} productListID is for product list
 * @return promise - axios returns a promise
 */
export const getProductPrice = (id, productListID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/erp/aaro/products/price/${id}?priceListID=${productListID}`;
    return axios.get(url, { headers });
};

/**
 * @desc retrieves product history in aaro based on query parameters
 * @param {Object} query - query parameters for filtering history
 * @return promise - axios returns a promise
 */
export const getAaroProductHistory = (query = {}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const queryString = new URLSearchParams(query).toString();
    const url = `${config.url.API_URL}/api/erp/aaro/history${queryString ? `?${queryString}` : ''}`;
    return axios.get(url, { headers });
};
