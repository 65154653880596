import { useState } from 'react';
import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import TodoCards from './TodoCards';
import { useTodoQueries } from 'hooks/aaro/useTodoQueries';
import CircularProgress from '@mui/material/CircularProgress';

export default function Todo({ params }) {
    const [page, setPage] = useState(1);
    const { todosQuery } = useTodoQueries({
        Sayfa: page,
        SayfaSatirSayisi: 100,
        ...params,
    });

    if (todosQuery.isLoading) {
        return (
            <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </MDBox>
        );
    }

    if (todosQuery.isError) {
        return (
            <Card>
                <MDBox p={3}>
                    <MDTypography variant="body2" color="error">
                        Görevler yüklenirken bir hata oluştu.
                    </MDTypography>
                </MDBox>
            </Card>
        );
    }

    const todos = todosQuery.data || [];

    // Görevleri tamamlanma durumuna göre ayır
    const pendingTodos = todos.filter((todo) => !todo.Tamamlandi);
    const completedTodos = todos.filter((todo) => todo.Tamamlandi);

    return (
        <MDBox>
            <MDBox>
                <TodoCards title="Devam Eden Görevler" todos={pendingTodos} />
            </MDBox>

            {completedTodos.length > 0 && (
                <MDBox mt={3}>
                    <TodoCards title="Tamamlanan Görevler" todos={completedTodos} />
                </MDBox>
            )}
        </MDBox>
    );
}
