// MD2 PRO
import MDBox from 'components/MDBox';
// @mui
import Grid from '@mui/material/Grid';

// API

import SingleProduct from 'layouts/orders/pages/SingleOrder/Sections/SalesDetailPage/components/SingleProduct';
// Helpers

function MainPage({ queries }) {
    const { orderItemsQuery } = queries;

    if (!orderItemsQuery?.data?.length) {
        return (
            <MDBox p={3} textAlign="center">
                Ürün bulunamadı.
            </MDBox>
        );
    }

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {/* {JSON.stringify(products)} */}

                    <MDBox display="flex" flexDirection="column" gap={3}>
                        {orderItemsQuery?.data?.map((product) => (
                            <SingleProduct key={product?.StokID} product={product} />
                        ))}
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default MainPage;
