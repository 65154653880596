import { useAaroQuery } from './useAaroQuery';
import { getContracts, getCustomerMovements, getProductMovements } from 'services/outside/aaro/aaroDekont';

export function useContractQueries(contractId, options = {}) {
    const contractQuery = useAaroQuery(['contract', contractId], () => getContracts({ SozlesmeID: contractId }), {
        ...options,
        requireSuccess: true,
    });

    const movementsQuery = useAaroQuery(
        ['customerMovements', contractId],
        () => getCustomerMovements({ SozlesmeID: contractId, SayfaSatirSayisi: 100 }),
        { ...options, requireSuccess: true }
    );

    const productMovementsQuery = useAaroQuery(
        ['productMovements', contractId],
        () => getProductMovements({ SozlesmeID: contractId, SayfaSatirSayisi: 100 }),
        { ...options, requireSuccess: true }
    );

    return {
        contractQuery,
        movementsQuery,
        productMovementsQuery,
    };
}
