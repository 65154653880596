import React, { useState, useRef } from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ContractsFilterForm from './FilterForm';
import ContractsGrid from './DisplayGrid';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';

export default function Contracts() {
    const [contractsData, setContractsData] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState(null);
    const formikRef = useRef(null);

    const handleDataFetched = (data, paginationData) => {
        setContractsData(data);
        setPaginationInfo(paginationData);
    };

    const handlePageChange = (newPage) => {
        if (formikRef.current) {
            formikRef.current.setFieldValue('Sayfa', newPage);
            formikRef.current.submitForm();
        }
    };

    return (
        <DashboardLayout>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox m={3}>
                            <ContractsFilterForm onDataFetched={handleDataFetched} ref={formikRef} />
                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <MDBox>
                            <ContractsGrid
                                data={contractsData}
                                paginationInfo={paginationInfo}
                                onPageChange={handlePageChange}
                            />
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}
