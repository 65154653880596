// react-router-dom components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function TodoCards({ title, todos }) {
    const renderItems = todos.map((todo, key) => {
        const isOverdue = new Date(todo.TarihBit) < new Date() && !todo.Tamamlandi;

        return (
            <MDBox
                key={todo.GorevID}
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderRadius="lg"
                py={1}
                pr={2}
                mb={todos.length - 1 === key ? 0 : 1}
                sx={{
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'rgba(0,0,0,0.02)' },
                }}
                onClick={() => window.open(`https://erp2.aaro.com.tr/Gorevler2/Ozet2?id=${todo.GorevID}`, '_blank')}
            >
                <MDBox display="flex" alignItems="center">
                    <MDBox
                        display="grid"
                        alignItems="center"
                        justifyContent="center"
                        bgColor={todo.Tamamlandi ? 'success' : 'info'}
                        borderRadius="lg"
                        shadow="md"
                        color="white"
                        width="40px"
                        height="40px"
                        mr={2}
                        variant="gradient"
                        fontSize="0.875rem"
                        sx={{
                            minWidth: '40px',
                            minHeight: '40px',
                            flexShrink: 0,
                        }}
                    >
                        <Icon sx={{ fontSize: '20px' }}>{todo.Tamamlandi ? 'check_circle' : 'pending'}</Icon>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column">
                        <MDTypography
                            variant="button"
                            color={todo.Tamamlandi ? 'success' : isOverdue ? 'primary' : 'info'}
                            fontWeight="medium"
                            gutterBottom
                        >
                            {todo.GorevAdi} {isOverdue && '(Gecikmiş)'}
                        </MDTypography>
                        <MDTypography variant="caption" color="text">
                            {todo.Aciklama}
                        </MDTypography>
                        <MDTypography variant="caption" color="text">
                            Oluşturan: {todo.OlsAdi}
                        </MDTypography>
                        <MDTypography variant="caption" color="text">
                            Bitiş: {new Date(todo.TarihBit).toLocaleDateString('tr-TR')}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox display="flex">
                    <MDTypography
                        component={Link}
                        variant="button"
                        color={todo.Tamamlandi ? 'success' : isOverdue ? 'primary' : 'info'}
                        to={`/todo/${todo.GorevID}`}
                        sx={{
                            lineHeight: 0,
                            transition: 'all 0.2s cubic-bezier(.34,1.61,.7,1.3)',
                            p: 0.5,

                            '&:hover, &:focus': {
                                transform: 'translateX(5px)',
                            },
                        }}
                    >
                        <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
    });

    return (
        <Card>
            <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {title}
                </MDTypography>
            </MDBox>
            <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {renderItems}
                </MDBox>
            </MDBox>
        </Card>
    );
}

// Typechecking props for the TodoCards
TodoCards.propTypes = {
    title: PropTypes.string.isRequired,
    todos: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TodoCards;
