import ReactDiffViewer from 'react-diff-viewer';
import MDBox from 'components/MDBox';

function DiffViewer({ oldCode, newCode, type }) {
    const oldCodeObj = oldCode.length > 2 ? JSON.parse(oldCode) : {};
    const newCodeObj = newCode.length > 2 ? JSON.parse(newCode) : {};

    let availableKeysNEW = Object.keys(newCodeObj);
    let oldValueSTR = '';
    let newValueSTR = '';

    const stringifyValue = (value) => {
        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value, null, 2);
        }
        return value;
    };

    availableKeysNEW.forEach((element) => {
        oldValueSTR += element + ': ' + stringifyValue(oldCodeObj[element]) + '\n';
        newValueSTR += element + ': ' + stringifyValue(newCodeObj[element]) + '\n';
    });

    return (
        <MDBox width="100%">
            <ReactDiffViewer
                oldValue={oldValueSTR}
                newValue={newValueSTR}
                splitView={true}
                styles={{
                    contentText: {
                        fontSize: '12px',
                    },
                }}
            />
        </MDBox>
    );
}

export default DiffViewer;
