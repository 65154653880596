import React from 'react';
import { DataGridPro, GridFooterContainer } from '@mui/x-data-grid-pro';
import { Typography, Chip, Icon, Box } from '@mui/material';
import { memo } from 'react';

const TransactionTypeChip = memo(({ TipAdi }) => {
    const getTypeProperties = (tip) => {
        const actualType = tip.split(' - ')[0];

        switch (actualType) {
            case 'Satış Faturası':
                return { color: 'info', icon: 'receipt_long' };
            case 'Depolar Arası Transfer':
                return { color: 'warning', icon: 'swap_horiz' };
            case 'Alış Faturası':
                return { color: 'info', icon: 'shopping_cart' };
            case 'İade Faturası':
                return { color: 'error', icon: 'assignment_return' };
            case 'Üretimden Giriş':
                return { color: 'success', icon: 'precision_manufacturing' };
            case 'Fire':
                return { color: 'error', icon: 'remove_circle' };
            default:
                return { color: 'default', icon: 'inventory' };
        }
    };

    const { color, icon } = getTypeProperties(TipAdi);

    return <Chip label={TipAdi} color={color} icon={<Icon>{icon}</Icon>} variant="outlined" size="small" />;
});

const DaysChip = memo(({ value, dekontId }) => {
    const bugun = new Date();
    const farkGun = Math.floor((bugun - new Date(value)) / (1000 * 60 * 60 * 24));

    return (
        <a
            href={`https://erp2.aaro.com.tr/DekontFatura/Kalem?id=${dekontId}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
        >
            <Chip label={`${farkGun} gün`} color="default" variant="outlined" size="small" clickable />
        </a>
    );
});

function ProductMovements({ queries }) {
    const { productMovementsQuery: query } = queries;
    const columns = [
        {
            field: 'Tarih',
            headerName: 'Gün',
            flex: 0.15,
            renderCell: (params) => <DaysChip value={params.value} dekontId={params.row.DekontID} />,
        },
        {
            field: 'TipAdi',
            headerName: 'İşlem Tipi',
            flex: 0.3,
            renderCell: (params) => <TransactionTypeChip TipAdi={params.value} />,
        },
        {
            field: 'KartAdi',
            headerName: 'Ürün Adı',
            flex: 0.4,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
        },
        {
            field: 'Miktar',
            headerName: 'Miktar',
            flex: 0.15,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
        },

        {
            field: 'OlsAdi',
            headerName: 'İşlem Yapan',
            flex: 0.25,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
        },

        {
            field: 'Tutar',
            headerName: 'Tutar',
            flex: 0.25,
            valueFormatter: ({ value, row }) =>
                new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: row?.DovizKodu || 'TRY',
                }).format(value),
        },
    ];

    const footerSummary = React.useMemo(() => {
        if (!query.data) return [];

        const summaryByType = query.data.reduce((acc, curr) => {
            if (!acc[curr.TipAdi]) {
                acc[curr.TipAdi] = {
                    total: 0,
                    currency: curr.DovizKodu || 'TRY',
                    tipAdi: curr.TipAdi,
                };
            }
            acc[curr.TipAdi].total += curr.Tutar;
            return acc;
        }, {});

        return Object.entries(summaryByType).map(([tipAdi, { total, currency }]) => ({
            id: tipAdi,
            label: `${tipAdi} - ${new Intl.NumberFormat('tr-TR', {
                style: 'currency',
                currency: currency,
            }).format(total)}`,
        }));
    }, [query.data]);

    if (query.isError) {
        return <Typography color="error">Hata: {query.error.message}</Typography>;
    }

    return (
        <DataGridPro
            rows={query.data || []}
            columns={columns}
            autoHeight
            getRowId={(row) => row.ID || Math.random()}
            loading={query.isLoading}
            initialState={{
                pinnedColumns: {
                    left: ['Tarih'],
                },
            }}
            components={{
                Footer: () => (
                    <GridFooterContainer>
                        <Box sx={{ p: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {footerSummary.map(({ id, label }) => (
                                <TransactionTypeChip key={id} TipAdi={label} />
                            ))}
                        </Box>
                    </GridFooterContainer>
                ),
            }}
            componentsProps={{
                footer: {
                    sx: { borderTop: 1, borderColor: 'divider' },
                },
            }}
        />
    );
}

export default ProductMovements;
