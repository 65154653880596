import React from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useParams } from 'react-router-dom';
import { Card, Grid } from '@mui/material';
import CustomerMovements from './CustomerMovements';
import ProductMovements from './ProductMovements';
import OrderMovements from './OrderMovements';
import ContractDetail from './ContractDetail';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useQuery } from 'react-query';
import {
    getOrderProductMovements,
    getOrders,
    getOrdersDetailed,
    getContracts,
    getCustomerMovements,
    getProductMovements,
    getNotes,
} from 'services/outside/aaro/aaroDekont';
import FileViewGoogleDrive from 'components/General/File/FileViewGoogleDrive';
import Notes from './Notes';
import AaroTodo from 'components/Aaro/Todo';

function SingleContract() {
    const { id: contractId } = useParams();

    // Add contract queries
    const contractQuery = useQuery(['contract', contractId], async () => {
        const response = await getContracts({ SozlesmeID: contractId });
        if (!response.data.Sonuc) {
            throw new Error(response.data.Mesajlar?.Mesaj || 'Veri çekme hatası');
        }
        return response.data.Model[0];
    });

    const movementsQuery = useQuery(['customerMovements', contractId], async () => {
        const response = await getCustomerMovements({ SozlesmeID: contractId, SayfaSatirSayisi: 100 });
        if (!response.data.Sonuc) {
            throw new Error(response.data.Mesajlar?.Mesaj || 'Veri çekme hatası');
        }
        return response.data.Model;
    });

    // Move all queries here
    const orderMovementsQuery = useQuery(['orderMovements', contractId], async () => {
        const response = await getOrderProductMovements({ SozlesmeID: contractId, SayfaSatirSayisi: 100 });
        if (!response.data.Sonuc) {
            throw new Error(response.data.Mesajlar?.Mesaj || 'Veri çekme hatası');
        }
        return response.data.Model;
    });

    const uniqueDekontIds = React.useMemo(() => {
        if (!orderMovementsQuery.data) return [];
        return [...new Set(orderMovementsQuery.data.map((item) => item.DekontID))];
    }, [orderMovementsQuery.data]);

    const orderDetailsQuery = useQuery(
        ['orderDetails', uniqueDekontIds],
        async () => {
            if (uniqueDekontIds.length === 0) return [];
            const response = await getOrders({
                DekontID: uniqueDekontIds.join(','),
                SayfaSatirSayisi: 100,
            });
            return response?.data?.Model || [];
        },
        { enabled: uniqueDekontIds.length > 0 }
    );

    const ordersDetailedQuery = useQuery(['ordersDetailed', contractId], async () => {
        const response = await getOrdersDetailed({ SozlesmeID: contractId, SayfaSatirSayisi: 100 });
        if (!response.data.Sonuc) {
            throw new Error(response.data.Mesajlar?.Mesaj || 'Veri çekme hatası');
        }
        return response.data.Model;
    });

    // Add product movements query
    const productMovementsQuery = useQuery(['productMovements', contractId], async () => {
        const response = await getProductMovements({ SozlesmeID: contractId, SayfaSatirSayisi: 100 });
        if (!response.data.Sonuc) {
            throw new Error(response.data.Mesajlar?.Mesaj || 'Veri çekme hatası');
        }
        return response.data.Model;
    });

    // Get notes returns all the notes for the contract and not needed to extract like response.data.Model
    const notesQuery = useQuery(['notes', contractId], async () => {
        const response = await getNotes('Sozlesme', contractId);
        return response;
    });

    // Create a queries object to pass down
    const queries = {
        orderMovementsQuery,
        orderDetailsQuery,
        ordersDetailedQuery,
        contractQuery,
        movementsQuery,
        productMovementsQuery,
        notesQuery,
    };

    return (
        <DashboardLayout>
            <MDBox p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        {/* Sipariş Hareketleri */}
                        <Card sx={{ mb: 3 }}>
                            <MDBox p={3}>
                                <MDBox mb={3}>
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Sipariş Hareketleri
                                    </MDTypography>
                                </MDBox>
                                <OrderMovements contractId={contractId} queries={queries} />
                            </MDBox>
                        </Card>
                        {/* Müşteri Hareketleri */}
                        <Card sx={{ mb: 3 }}>
                            <MDBox p={3}>
                                <MDBox mb={3}>
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Tahsilatlar
                                    </MDTypography>
                                </MDBox>
                                <CustomerMovements contractId={contractId} queries={queries} />
                            </MDBox>
                        </Card>

                        {/* Ürün Hareketleri */}
                        <Card sx={{ mb: 3 }}>
                            <MDBox p={3}>
                                <MDBox mb={3}>
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Ürün Hareketleri
                                    </MDTypography>
                                </MDBox>
                                <ProductMovements contractId={contractId} queries={queries} />
                            </MDBox>
                        </Card>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <MDBox mb={3}>
                            <ContractDetail contractId={contractId} queries={queries} />
                        </MDBox>
                        <MDBox mb={3}>
                            <FileViewGoogleDrive
                                folderId={'0AL2y31SDbzlTUk9PVA'}
                                getOrCreate={true}
                                getOrCreateFolderName={contractId}
                                documents={['Sözleşme Dosyaları']}
                            />
                        </MDBox>
                        <MDBox mb={3}>
                            <Notes notes={notesQuery.data} />
                        </MDBox>
                        <MDBox mt={3}>
                            <AaroTodo params={{ TabloSatirID: contractId }} />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default SingleContract;
