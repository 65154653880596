import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { getCustomerMovements } from 'services/outside/aaro/aaroDekont';
import { useQueries } from 'react-query';
import SMProgress from 'components/SMComponents/SMProgress';

const ContractsGrid = ({ data, paginationInfo, onPageChange }) => {
    // Her bir sözleşme için müşteri hareketlerini çek
    const results = useQueries(
        (data || []).map((contract) => ({
            queryKey: ['customerMovements', contract.SozlesmeID],
            queryFn: async () => {
                const response = await getCustomerMovements({ SozlesmeID: contract.SozlesmeID });
                if (!response.data.Sonuc) {
                    throw new Error(response.data.Mesajlar?.Mesaj || 'Veri çekme hatası');
                }
                return response.data;
            },
            enabled: !!contract.SozlesmeID,
        }))
    );

    // Müşteri hareketlerinden toplam tutarı hesapla
    const calculateTotalAmount = (movements) => {
        return (
            movements?.reduce((sum, movement) => {
                // Alış (A) hareketlerini topla
                if (movement.BA === 'A') {
                    return sum + movement.Tutar;
                }
                return sum;
            }, 0) || 0
        );
    };

    const columns = [
        {
            field: 'SozlesmeID',
            headerName: 'ID',
            width: 100,
            renderCell: (params) => (
                <Chip
                    label={params.value}
                    color="default"
                    variant="outlined"
                    clickable
                    onClick={() => {
                        window.open(`/reports/contracts/${params.value}`, '_blank');
                    }}
                />
            ),
        },
        {
            field: 'progress',
            headerName: 'Tahsilat Durumu',
            width: 180,
            renderCell: (params) => {
                const contractIndex = data.findIndex((c) => c.SozlesmeID === params.row.SozlesmeID);
                const result = results[contractIndex];

                if (result.isLoading) {
                    return <LinearProgress sx={{ width: '100%' }} />;
                }

                if (result.isError) {
                    return <Typography color="error">Veri alınamadı</Typography>;
                }

                const totalCollected = calculateTotalAmount(result.data?.Model);
                const contractAmount = params.row.Tutar;
                const progressValue = (totalCollected / contractAmount) * 100;

                return <SMProgress value={progressValue} />;
            },
        },
        {
            field: 'SozlesmeAdi',
            headerName: 'Sözleşme Adı',
            width: 200,
            renderCell: (params) => (
                <MDTypography variant="body2" textTransform="capitalize">
                    {params.value}
                </MDTypography>
            ),
        },
        { field: 'CariAdi', headerName: 'Cari Adı', width: 300 },
        { field: 'CariKodu', headerName: 'Cari Kodu', width: 120 },
        { field: 'TipAdi', headerName: 'Tip', width: 100 },
        {
            field: 'Tutar',
            headerName: 'Tutar',
            width: 150,
            renderCell: (params) => (
                <MDTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {new Intl.NumberFormat('tr-TR', {
                        style: 'currency',
                        currency: 'TRY',
                    }).format(params.value)}
                </MDTypography>
            ),
        },
        {
            field: 'Tarih',
            headerName: 'Tarih',
            width: 120,
            type: 'date',
            valueFormatter: (params) => {
                return new Date(params.value).toLocaleDateString('tr-TR');
            },
        },
        { field: 'DovizSembol', headerName: 'Döviz', width: 70 },
        { field: 'SozlesmeKodu', headerName: 'Sözleşme Kodu', width: 180 },
        { field: 'OnayDurum', headerName: 'Onay Durumu', width: 120 },
        { field: 'OlsAdi', headerName: 'Oluşturan', width: 130 },
        { field: 'DgsAdi', headerName: 'Değiştiren', width: 130 },
    ];

    return (
        <MDBox>
            <DataGridPro
                rows={data || []}
                columns={columns}
                autoHeight
                disableSelectionOnClick
                getRowId={(row) => row.SozlesmeID}
                paginationMode="server"
                rowCount={paginationInfo?.ToplamSatirSayisi || 0}
                pageSize={paginationInfo?.SayfaSatirSayisi || 10}
                page={paginationInfo?.Sayfa - 1}
                onPageChange={(newPage) => onPageChange(newPage + 1)}
                pagination
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'Tarih', sort: 'desc' }],
                    },
                }}
            />
        </MDBox>
    );
};

export default ContractsGrid;
