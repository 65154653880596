import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { formatPrice } from 'helpers/priceHelpers';
function GeneralSummary({ orderDetails, orderProducts, shadow = true }) {
    const info = {
        'Belge No': (
            <a
                href={`https://erp2.aaro.com.tr/DekontFatura/Kalem?id=${orderDetails?.DekontID}`}
                target="_blank"
                rel="noreferrer"
            >
                {orderDetails?.BelgeNo}
            </a>
        ),
        Sözleşme: (
            <a
                href={`http://localhost:3000/reports/contracts/${orderDetails?.SozlesmeID}`}
                target="_blank"
                rel="noreferrer"
            >
                {orderDetails?.SozlesmeAdi || ''}
            </a>
        ),
        Müşteri: orderDetails?.KartAdi || '-',
        'Toplam Ürün': `${orderProducts.length || 0} Kalem`,
        'Toplam Tutar': `${formatPrice(orderDetails?.Tutar || 0)}`,
        Tarih: new Date(orderDetails?.OlsTar).toLocaleDateString('tr-TR'),
        'Üretim Oranı': `%${orderDetails?.UretimOrani || 0}`,
        'Sevkiyat Oranı': `%${orderDetails?.SevkiyatOrani || 0}`,
    };

    const labels = Object.keys(info);
    const values = Object.values(info);

    const renderItems = labels.map((label, key) => (
        <MDBox key={label} display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {label}: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{values[key]}
            </MDTypography>
        </MDBox>
    ));

    return (
        <Card sx={{ height: '100%', boxShadow: !shadow && 'none' }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Sipariş Özeti
                </MDTypography>
                <MDTypography variant="body2" color="secondary">
                    <Icon>print</Icon>
                </MDTypography>
            </MDBox>
            <MDBox p={2}>
                <MDBox mb={2} lineHeight={1}>
                    <MDTypography variant="button" color="text" fontWeight="light">
                        Sipariş detayları ve özet bilgiler
                    </MDTypography>
                </MDBox>
                <MDBox opacity={0.3}>
                    <Divider />
                </MDBox>
                <MDBox>{renderItems}</MDBox>
            </MDBox>
        </Card>
    );
}

GeneralSummary.propTypes = {
    orderDetails: PropTypes.object.isRequired,
    orderProducts: PropTypes.array.isRequired,
    shadow: PropTypes.bool,
};

GeneralSummary.defaultProps = {
    shadow: true,
};

export default GeneralSummary;
