import Analytics from 'layouts/dashboards/analytics';
import StockMovements from 'layouts/reports/stockMovements';
import Contracts from 'layouts/reports/contracts';
import SingleContract from 'layouts/reports/contracts/SingleContract';
import SignInBasic from 'layouts/authentication/sign-in/basic';
import ProfilePage from 'layouts/accounts';

// import ProjectsPage from 'layouts/projects/Pages/AllProjects';
import ProjectsPage from 'layouts/projects/Pages/All/index.js';

// import AllCustomers from 'layouts/customers/pages/PageNav';
// import AllReceipts from 'layouts/receipt/pages/PageNav';
// import Optimizer from 'layouts/optimizer';
import LeadsPage from 'layouts/lead/pages/allLeads';

import FastPricePage from 'layouts/aaro/fastPrice';
import SingleProject from 'layouts/projects/Pages/SingleProject';
// import Settings from 'layouts/pages/settings';

import AllOrders from 'layouts/orders/pages/AllOrders';
import SingleOrder from 'layouts/orders/pages/SingleOrder';
import SingleCustomer from 'layouts/customers/pages/SingleCustomer';

// * Jotform
// import BelgeOnayi from 'layouts/jotform/forms/belge-onayi';
// import Nakliye from 'layouts/jotform/forms/nakliye';
// import MusteriZiyareti from 'layouts/jotform/forms/musteri-ziyareti';

import Task from 'components/General/Task/Views/Task/PageView';
import TaskList from 'components/General/Task/Views/TaskList/PageView';

// Material Dashboard 2 PRO React components
import MDAvatar from 'components/MDAvatar';

// @mui icons
import Icon from '@mui/material/Icon';
import SingleLead from 'layouts/lead/pages/singleLead';

// Product Suggestions
import ProductSuggestions from 'layouts/productSuggestions';

// Settings
import Settings from 'layouts/settings/index.js';

// Parameters
import Parameters from 'layouts/pages/settings/index.js';

// To do
import Todo from 'layouts/todo';

// Ahsaplar
import Purchase from 'layouts/purchases/pages/singleLead';
import PurchasePage from 'layouts/purchases/pages/allLeads';

// Google Callback
import GoogleCallback from 'layouts/accounts/pages/GoogleCallback';

// Images
const user = JSON.parse(localStorage.getItem('user'));

const routes = [
    // hidden routes
    {
        hide: true,
        route: '/projects/:id',
        name: 'Proje',
        key: 'proje',
        component: <SingleProject />,
        noCollapse: true,
    },
    {
        hide: true,
        route: '/leads/:id',
        name: 'Lead',
        key: 'lead',
        component: <SingleLead />,
        noCollapse: true,
    },
    {
        hide: true,
        route: '/purchase/:id',
        name: 'Purchase',
        key: 'purchase',
        component: <Purchase />,
        noCollapse: true,
    },

    {
        hide: true,
        route: '/customers/:id',
        name: 'Müşteri',
        key: 'musteri',
        component: <SingleCustomer />,
        noCollapse: true,
    },
    {
        hide: true,
        route: '/orders/:id',
        name: 'Order',
        key: 'singleOrder',
        component: <SingleOrder />,
        noCollapse: true,
    },
    {
        hide: true,
        route: '/taskList/:id',
        name: 'TaskList',
        key: 'taskList',
        component: <TaskList />,
        noCollapse: true,
    },
    {
        hide: true,
        route: '/task/:id',
        name: 'Task',
        key: 'taskItem',
        component: <Task />,
        noCollapse: true,
    },
    {
        hide: true,
        name: 'Parameters',
        key: 'parameters',
        route: '/parameters',
        component: <Parameters />,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: user?.name,
        key: user?.name,
        icon: <MDAvatar src={user?.profilePicture} alt={user?.name} size="sm" />,
        collapse: [
            // {
            // 	name: 'Profil',
            // 	key: 'my-profile',
            // 	route: '/pages/profile/profile-overview',
            // 	component: <ProfileOverview />,
            // },
            {
                name: 'Profil',
                key: 'profile-settings',
                route: '/account/settings',
                component: <ProfilePage />,
            },
            {
                name: 'Çıkış',
                key: 'logout',
                route: '/login',
                component: <SignInBasic />,
            },
        ],
    },
    { type: 'divider', key: 'divider-0' },
    {
        type: 'collapse',
        name: 'Ana Sayfa',
        key: 'dashboards',
        icon: <Icon fontSize="medium">dashboard</Icon>,
        collapse: [
            {
                name: 'Genel',
                key: 'analytics',
                route: '/dashboards/analytics',
                component: <Analytics />,
            },
            // {
            // 	name: 'İstatistik',
            // 	key: 'sales',
            // 	route: '/dashboards/sales',
            // 	component: <Sales />,
            // },
        ],
    },
    { type: 'title', title: 'KHR', key: 'title-pages' },
    {
        type: 'collapse',
        name: 'Yapılacaklar',
        key: 'todos',
        route: 'todo/',
        component: <Todo />,
        icon: <Icon fontSize="medium">task_alt</Icon>,
        noCollapse: true,
        // collapse: [],
    },

    {
        type: 'collapse',
        name: 'Fırsatlar',
        key: 'leads',
        route: 'leads/',
        component: <LeadsPage />,
        icon: <Icon fontSize="medium">attach_money</Icon>,

        noCollapse: true,
        // collapse: [],
    },

    // {
    //     type: 'collapse',
    //     name: 'Müşteriler',
    //     key: 'customers',
    //     route: 'customers/',
    //     component: <AllCustomers />,
    //     icon: <Icon fontSize="medium">group</Icon>,

    //     noCollapse: true,
    //     // collapse: [],
    // },

    {
        type: 'collapse',
        name: 'Projeler',
        key: 'projects',
        route: 'projects/',
        component: <ProjectsPage />,
        icon: <Icon fontSize="medium">work</Icon>,

        noCollapse: true,
        // collapse: [],
    },
    {
        type: 'collapse',
        name: 'Ürün Önerileri',
        key: 'suggestions',
        route: 'suggestions/',
        component: <ProductSuggestions />,
        icon: <Icon fontSize="medium">emoji_objects</Icon>,

        noCollapse: true,
        // collapse: [],
    },
    // {
    // 	type: 'collapse',
    // 	name: 'Görevler',
    // 	key: 'todo',
    // 	route: 'todo/',
    // 	component: <TodoPage />,
    // 	icon: <Icon fontSize="medium">assignment_turned</Icon>,

    // 	noCollapse: true,
    // 	// collapse: [],assignment_turned_in
    // },
    { type: 'divider', key: 'divider-aaro' },
    { type: 'title', title: 'Aaro', key: 'aaro-general' },
    {
        type: 'collapse',
        name: 'Hızlı Fiyat',
        key: 'fastPrice',
        route: 'fast/',
        component: <FastPricePage />,
        icon: <Icon fontSize="medium">offline_bolt</Icon>,

        noCollapse: true,
        // collapse: [],
    },
    {
        type: 'collapse',
        name: 'Online Siparişler',
        key: 'purchases',
        route: 'purchases/',
        component: <PurchasePage />,
        icon: <Icon fontSize="medium">shopping_cart</Icon>,

        noCollapse: true,
        // collapse: [],
    },

    {
        type: 'collapse',
        name: 'Siparişler',
        key: 'orders',
        route: 'orders/',
        component: <AllOrders />,
        icon: <Icon fontSize="medium">factory</Icon>,

        noCollapse: true,
        // collapse: [],
    },
    {
        type: 'collapse',
        name: 'Raporlar',
        key: 'reports',
        icon: <Icon fontSize="medium">assessment</Icon>,
        collapse: [
            {
                name: 'Stok Hareketleri',
                key: 'stockMovements',
                route: '/reports/stockMovements',
                component: <StockMovements />,
            },
            {
                name: 'Sözleşmeler',
                key: 'contracts', //
                route: '/reports/contracts',
                component: <Contracts />,
            },

            // {
            // 	name: 'İstatistik',
            // 	key: 'sales',
            // 	route: '/dashboards/sales',
            // 	component: <Sales />,
            // },
        ],
    },

    {
        hide: true,
        type: 'collapse',
        name: 'Ayarlar',
        key: 'settings',
        route: 'settings/',
        component: <Settings />,
        icon: <Icon fontSize="medium">offline_bolt</Icon>,

        noCollapse: true,
        // collapse: [],
    },
    {
        hide: true,
        name: 'contract',
        key: 'contract', //
        route: '/reports/contracts/:id',
        component: <SingleContract />,
        icon: <Icon fontSize="medium">offline_bolt</Icon>,
        noCollapse: true,
    },
    // { type: 'divider', key: 'divider-1072' },

    // { type: 'title', title: 'Ekler', key: 'helpers' },
    // {
    //     type: 'collapse',
    //     name: 'Kapı Ölçülendirici',
    //     key: 'door-size-optimizer',
    //     route: 'door-size-optimizer/',
    //     component: <Optimizer />,
    //     icon: <Icon fontSize="medium">door_sliding</Icon>,

    //     noCollapse: true,
    //     // collapse: [],
    // },
    // {
    //     type: 'collapse',
    //     name: 'Reçeteler',
    //     key: 'receipt',
    //     route: 'receipt/',
    //     component: <AllReceipts />,
    //     icon: <Icon fontSize="medium">receipt</Icon>,

    //     noCollapse: true,
    //     // collapse: [],
    // },
    // {
    // 	type: 'collapse',
    // 	name: 'Pages',
    // 	key: 'pages',
    // 	icon: <Icon fontSize="medium">image</Icon>,
    // 	collapse: [
    // 		{
    // 			name: 'Users',
    // 			key: 'users',
    // 			collapse: [
    // 				{
    // 					name: 'New User',
    // 					key: 'new-user',
    // 					route: '/pages/users/new-user',
    // 					component: <NewUser />,
    // 				},
    // 			],
    // 		},
    // 		{
    // 			name: 'Account',
    // 			key: 'account',
    // 			collapse: [
    // 				{
    // 					name: 'Settings',
    // 					key: 'settings',
    // 					route: '/pages/account/settings',
    // 					component: <Settings />,
    // 				},
    // 				{
    // 					name: 'Billing',
    // 					key: 'billing',
    // 					route: '/pages/account/billing',
    // 					component: <Billing />,
    // 				},
    // 				{
    // 					name: 'Invoice',
    // 					key: 'invoice',
    // 					route: '/pages/account/invoice',
    // 					component: <Invoice />,
    // 				},
    // 			],
    // 		},
    // 	],
    // },
    // 		// {
    // 		// 	name: 'Projects',
    // 		// 	key: 'projects',
    // 		// 	collapse: [
    // 		// 		{
    // 		// 			name: 'Timeline',
    // 		// 			key: 'timeline',
    // 		// 			route: '/pages/projects/timeline',
    // 		// 			component: <Timeline />,
    // 		// 		},
    // 		// 	],
    // 		// },
    // 		{
    // 			name: 'Pricing Page',
    // 			key: 'pricing-page',
    // 			route: '/pages/pricing-page',
    // 			component: <PricingPage />,
    // 		},
    // 		{
    // 			name: 'RTL',
    // 			key: 'rtl',
    // 			route: '/pages/rtl',
    // 			component: <RTL />,
    // 		},
    // 		{
    // 			name: 'Widgets',
    // 			key: 'widgets',
    // 			route: '/pages/widgets',
    // 			component: <Widgets />,
    // 		},
    // 		{
    // 			name: 'Charts',
    // 			key: 'charts',
    // 			route: '/pages/charts',
    // 			component: <Charts />,
    // 		},
    // 		{
    // 			name: 'Notfications',
    // 			key: 'notifications',
    // 			route: '/pages/notifications',
    // 			component: <Notifications />,
    // 		},
    // 	],
    // },
    // {
    // 	type: 'collapse',
    // 	name: 'Applications',
    // 	key: 'applications',
    // 	icon: <Icon fontSize="medium">apps</Icon>,
    // 	collapse: [
    // 		{
    // 			name: 'Kanban',
    // 			key: 'kanban',
    // 			route: '/applications/kanban',
    // 			component: <Kanban />,
    // 		},
    // 		{
    // 			name: 'Wizard',
    // 			key: 'wizard',
    // 			route: '/applications/wizard',
    // 			component: <Wizard />,
    // 		},
    // 		{
    // 			name: 'Data Tables',
    // 			key: 'data-tables',
    // 			route: '/applications/data-tables',
    // 			component: <DataTables />,
    // 		},
    // 		{
    // 			name: 'Calendar',
    // 			key: 'calendar',
    // 			route: '/applications/calendar',
    // 			component: <Calendar />,
    // 		},
    // 	],
    // },
    // {
    // 	type: 'collapse',
    // 	name: 'Ecommerce',
    // 	key: 'ecommerce',
    // 	icon: <Icon fontSize="medium">shopping_basket</Icon>,
    // 	collapse: [
    // 		{
    // 			name: 'Products',
    // 			key: 'products',
    // 			collapse: [
    // 				{
    // 					name: 'New Product',
    // 					key: 'new-product',
    // 					route: '/ecommerce/products/new-product',
    // 					component: <NewProduct />,
    // 				},
    // 				{
    // 					name: 'Edit Product',
    // 					key: 'edit-product',
    // 					route: '/ecommerce/products/edit-product',
    // 					component: <EditProduct />,
    // 				},
    // 				{
    // 					name: 'Product Page',
    // 					key: 'product-page',
    // 					route: '/ecommerce/products/product-page',
    // 					component: <ProductPage />,
    // 				},
    // 			],
    // 		},
    // 		{
    // 			name: 'Orders',
    // 			key: 'orders',
    // 			collapse: [
    // 				{
    // 					name: 'Order List',
    // 					key: 'order-list',
    // 					route: '/ecommerce/orders/order-list',
    // 					component: <OrderList />,
    // 				},
    // 				{
    // 					name: 'Order Details',
    // 					key: 'order-details',
    // 					route: '/ecommerce/orders/order-details',
    // 					component: <OrderDetails />,
    // 				},
    // 			],
    // 		},
    // 	],
    // },
    // {
    // 	type: 'collapse',
    // 	name: 'Authentication',
    // 	key: 'authentication',
    // 	icon: <Icon fontSize="medium">content_paste</Icon>,
    // 	collapse: [
    // 		{
    // 			name: 'Sign In',
    // 			key: 'sign-in',
    // 			collapse: [
    // 				{
    // 					name: 'Basic',
    // 					key: 'basic',
    // 					route: '/authentication/sign-in',
    // 					component: <SignInBasic />,
    // 				},
    // 				{
    // 					name: 'Cover',
    // 					key: 'cover',
    // 					route: '/authentication/sign-in/cover',
    // 					component: <SignInCover />,
    // 				},
    // 				{
    // 					name: 'Illustration',
    // 					key: 'illustration',
    // 					route: '/authentication/sign-in/illustration',
    // 					component: <SignInIllustration />,
    // 				},
    // 			],
    // 		},
    // 		{
    // 			name: 'Sign Up',
    // 			key: 'sign-up',
    // 			collapse: [
    // 				{
    // 					name: 'Cover',
    // 					key: 'cover',
    // 					route: '/authentication/sign-up/cover',
    // 					component: <SignUpCover />,
    // 				},
    // 			],
    // 		},
    // 		{
    // 			name: 'Reset Password',
    // 			key: 'reset-password',
    // 			collapse: [
    // 				{
    // 					name: 'Cover',
    // 					key: 'cover',
    // 					route: '/authentication/reset-password/cover',
    // 					component: <ResetCover />,
    // 				},
    // 			],
    // 		},
    // 	],
    // },
    // { type: 'divider', key: 'divider-1071' },
    // { type: 'title', title: 'ATB', key: 'title-airtable' },

    // {
    //     type: 'collapse',
    //     name: 'Fiyat Listeleri',
    //     key: 'basic',
    //     icon: <Icon fontSize="medium">attach_file</Icon>,
    //     collapse: [
    //         {
    //             name: 'Listeler',
    //             key: 'airtable-lists',
    //             href: 'https://airtable.com/shr7YuYvJrdDAbnFc',
    //         },
    //         {
    //             name: 'Yeni Liste Ekle',
    //             key: 'airtable-add-list',
    //             href: 'https://airtable.com/shrQHmImZG16BHGyv',
    //         },
    //     ],
    // },

    // { type: 'divider', key: 'divider-1' },
    // { type: 'title', title: 'Diger', key: 'title-diger' },

    // { type: 'title', title: 'JTF', key: 'title-jotform' },
    // {
    //     type: 'collapse',
    //     name: 'Satış Pazarlama',
    //     key: 'satis',
    //     icon: <Icon fontSize="medium">star_rate</Icon>,
    //     collapse: [
    //         {
    //             type: 'collapse',
    //             name: 'Müşteri Ziyareti',
    //             key: 'musteriZiyareti',
    //             route: 'jotform/customer-visiting',
    //             component: <MusteriZiyareti />,
    //             icon: <Icon fontSize="medium">work</Icon>,
    //             noCollapse: true,
    //             // collapse: [],
    //         },
    //     ],
    // },
    // {
    //     type: 'collapse',
    //     name: 'Fabrika',
    //     key: 'fabrika',
    //     icon: <Icon fontSize="medium">factory</Icon>,
    //     collapse: [
    //         // {
    //         // 	type: 'collapse',
    //         // 	name: 'Siparişler',
    //         // 	key: 'siparisler',
    //         // 	route: 'jotform/factory-orders',
    //         // 	component: <ProjectsPage />,
    //         // 	icon: <Icon fontSize="medium">work</Icon>,

    //         // 	noCollapse: true,
    //         // 	// collapse: [],
    //         // },
    //         {
    //             type: 'collapse',
    //             name: 'Nakliye Çıkış',
    //             key: 'nakliye',
    //             route: 'jotform/transport',
    //             component: <Nakliye />,
    //             icon: <Icon fontSize="medium">work</Icon>,

    //             noCollapse: true,
    //             // collapse: [],
    //         },
    //     ],
    // },
    // {
    //     type: 'collapse',
    //     name: 'Belge Onayı',
    //     key: 'document',
    //     route: 'jotform/document-confirmation',
    //     component: <BelgeOnayi />,
    //     icon: <Icon fontSize="medium">article</Icon>,
    //     noCollapse: true,
    //     // collapse: [],
    // },

    // {
    // 	type: 'collapse',
    // 	name: 'Basic',
    // 	key: 'basic',
    // 	icon: <Icon fontSize="medium">upcoming</Icon>,
    // 	collapse: [
    // 		{
    // 			name: 'Getting Started',
    // 			key: 'getting-started',
    // 			collapse: [
    // 				{
    // 					name: 'Overview',
    // 					key: 'overview',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/',
    // 				},
    // 				{
    // 					name: 'License',
    // 					key: 'license',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/license/material-dashboard/',
    // 				},
    // 				{
    // 					name: 'Quick Start',
    // 					key: 'quick-start',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/',
    // 				},
    // 				{
    // 					name: 'Build Tools',
    // 					key: 'build-tools',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/',
    // 				},
    // 			],
    // 		},
    // 		{
    // 			name: 'Foundation',
    // 			key: 'foundation',
    // 			collapse: [
    // 				{
    // 					name: 'Colors',
    // 					key: 'colors',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/',
    // 				},
    // 				{
    // 					name: 'Grid',
    // 					key: 'grid',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/',
    // 				},
    // 				{
    // 					name: 'Typography',
    // 					key: 'base-typography',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/',
    // 				},
    // 				{
    // 					name: 'Borders',
    // 					key: 'borders',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/',
    // 				},
    // 				{
    // 					name: 'Box Shadows',
    // 					key: 'box-shadows',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/',
    // 				},
    // 				{
    // 					name: 'Functions',
    // 					key: 'functions',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/',
    // 				},
    // 				{
    // 					name: 'Routing System',
    // 					key: 'routing-system',
    // 					href: 'https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/',
    // 				},
    // 			],
    // 		},
    // 	],
    // },
    // {
    // 	type: 'collapse',
    // 	name: 'Components',
    // 	key: 'components',
    // 	icon: <Icon fontSize="medium">view_in_ar</Icon>,
    // 	collapse: [
    // 		{
    // 			name: 'Alerts',
    // 			key: 'alerts',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Avatar',
    // 			key: 'avatar',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Badge',
    // 			key: 'badge',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Badge Dot',
    // 			key: 'badge-dot',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Box',
    // 			key: 'box',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/box/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Buttons',
    // 			key: 'buttons',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Date Picker',
    // 			key: 'date-picker',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Dropzone',
    // 			key: 'dropzone',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Editor',
    // 			key: 'editor',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Input',
    // 			key: 'input',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/input/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Pagination',
    // 			key: 'pagination',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Progress',
    // 			key: 'progress',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Snackbar',
    // 			key: 'snackbar',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Social Button',
    // 			key: 'social-button',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/',
    // 		},
    // 		{
    // 			name: 'Typography',
    // 			key: 'typography',
    // 			href: 'https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/',
    // 		},
    // 	],
    // },
    // {
    // 	type: 'collapse',
    // 	name: 'Yenilikler',
    // 	key: 'changelog',
    // 	href: '#',
    // 	icon: <Icon fontSize="medium">receipt_long</Icon>,
    // 	noCollapse: true,
    // },
    {
        type: 'route',
        name: 'Google Callback',
        key: 'google-callback',
        route: '/account/google-callback',
        component: <GoogleCallback />,
        noCollapse: true,
        hidden: true,
    },
];

export default routes;
