import PropTypes from 'prop-types';
import { Card, Icon } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { format } from 'date-fns';

function Notes({ notes }) {
    return (
        <MDBox mt={3}>
            <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        Sipariş Notları
                    </MDTypography>
                    <MDBox display="flex" alignItems="flex-start">
                        <MDBox color="text" mr={0.5} lineHeight={0}>
                            <Icon color="inherit" fontSize="small">
                                note
                            </Icon>
                        </MDBox>
                    </MDBox>
                </MDBox>

                <MDBox pt={3} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} sx={{ listStyle: 'none' }}>
                        {notes &&
                            notes.map((note) => (
                                <MDBox
                                    key={note.NotID}
                                    component="li"
                                    p={2}
                                    mb={2}
                                    borderRadius="lg"
                                    bgColor="grey-100"
                                >
                                    <MDTypography variant="body2" color="text" mb={1}>
                                        {note.Notu}
                                    </MDTypography>

                                    <MDBox display="flex" alignItems="center" gap={2}>
                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {note.OlsAdi} ({note.OlsKodu})
                                        </MDTypography>

                                        <MDTypography variant="caption" color="text" fontWeight="medium">
                                            {format(new Date(note.OlsTar), 'dd.MM.yyyy HH:mm')}
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            ))}
                    </MDBox>
                </MDBox>
            </Card>
        </MDBox>
    );
}

Notes.propTypes = {
    notes: PropTypes.arrayOf(
        PropTypes.shape({
            NotID: PropTypes.number,
            Notu: PropTypes.string,
            OlsAdi: PropTypes.string,
            OlsKodu: PropTypes.string,
            SubeAdi: PropTypes.string,
            SirketAdi: PropTypes.string,
            OlsTar: PropTypes.string,
        })
    ),
};

export default Notes;
