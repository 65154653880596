import React, { useMemo, memo } from 'react';
import { DataGridPro, GridFooterContainer } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import Icon from '@mui/material/Icon';
import Chip from '@mui/material/Chip';
import SMProgress from 'components/SMComponents/SMProgress';
import useWindowDimensions from 'hooks/useWindowDimensions';
import MDTypography from 'components/MDTypography';

const CustomFooter = memo(({ rows }) => {
    const producedAmount = useMemo(() => {
        return rows.reduce((sum, row) => {
            console.log('Row:', row);
            const amount = parseFloat(row.amount || 0);
            const production = parseFloat(row.production || 0);
            console.log('Amount:', amount, 'Production:', production);
            return sum + (amount * production) / 100;
        }, 0);
    }, [rows]);

    const shippedAmount = useMemo(() => {
        return rows.reduce((sum, row) => {
            const amount = parseFloat(row.amount || 0);
            const shipment = parseFloat(row.shipment || 0);
            return sum + (amount * shipment) / 100;
        }, 0);
    }, [rows]);

    const formatCurrency = (value) => {
        if (isNaN(value)) return '0₺';

        return new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY',
        })
            .format(value)
            .replace('TRY', '₺');
    };

    return (
        <GridFooterContainer sx={{ padding: '10px', justifyContent: 'flex-start', gap: 2 }}>
            <Chip
                label={`Üretilen Tutar: ${formatCurrency(producedAmount)}`}
                color="primary"
                icon={<Icon>factory</Icon>}
                variant="outlined"
            />
            <Chip
                label={`Sevk Edilen Tutar: ${formatCurrency(shippedAmount)}`}
                color="warning"
                icon={<Icon>local_shipping</Icon>}
                variant="outlined"
            />
        </GridFooterContainer>
    );
});

function OrderMovements({ queries }) {
    const { width } = useWindowDimensions();
    const { orderMovementsQuery, orderDetailsQuery, ordersDetailedQuery } = queries;

    const calculateTime = (date) => {
        const currentDate = new Date();
        let timePassed = currentDate - new Date(date);
        const twentyFourHoursToMS = 86400000;
        return Math.ceil(timePassed / twentyFourHoursToMS);
    };

    const orderColumns = [
        {
            field: 'olusmaTarihi',
            headerName: 'Gün',
            flex: 0.2,
            hide: width <= 600,
            renderCell: (params) => (
                <ProjectButtons name={`${calculateTime(params.value)} gün`} hareketId={params.row.HareketID} />
            ),
        },
        {
            field: 'type',
            headerName: 'Onay Durumu',
            flex: width <= 600 ? 0.6 : 0.3,
            renderCell: (params) => <SituationButtons OnayDurum={params.value} HareketID={params.row.HareketID} />,
        },
        {
            field: 'BelgeNo',
            headerName: 'Belge No',
            flex: 0.4,
            renderCell: (params) => (
                <MDTypography variant="body2" textTransform="capitalize">
                    {params.formattedValue}
                </MDTypography>
            ),
        },
        {
            field: 'name',
            headerName: 'Adı',
            flex: 0.1,
            renderCell: (params) => (
                <MDTypography variant="body2" textTransform="capitalize">
                    {params.formattedValue}
                </MDTypography>
            ),
        },
        {
            field: 'production',
            headerName: 'Üretim Oranı',
            flex: 0.4,
            renderCell: (params) => <SMProgress value={params.value} />,
        },
        {
            field: 'shipment',
            headerName: 'Sevkiyat Oranı',
            flex: 0.4,
            renderCell: (params) => <SMProgress value={params.value} />,
        },
        {
            field: 'price',
            headerName: 'Sipariş Tutarı',
            flex: 0.3,
            hide: width <= 600,
            renderCell: (params) => {
                const amount = parseFloat(params.value);
                const currencySymbol = params.value.replace(/[0-9.,]/g, '').trim();

                const formattedValue = new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: 'TRY',
                })
                    .format(amount)
                    .replace('TRY', currencySymbol);

                return (
                    <MDTypography
                        variant="body2"
                        textTransform="capitalize"
                        sx={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                        {formattedValue}
                    </MDTypography>
                );
            },
        },
    ];

    const orderRowValues = useMemo(() => {
        return (
            orderDetailsQuery.data?.map((el) => ({
                id: el?.DekontID,
                key: el?.customerID,
                olusmaTarihi: el?.OlsTar,
                BelgeNo: el?.BelgeNo,
                type: el?.OnayDurum,
                name: el?.KartAdi,
                production: el?.UretimOrani,
                shipment: el?.SevkiyatOrani,
                amount: el?.Tutar,
                price: el?.Tutar + '' + el?.DovizSembol,
                HareketID: el?.HareketID,
            })) || []
        );
    }, [orderDetailsQuery.data]);

    const detailColumns = [
        { field: 'StokKodu', headerName: 'Detaylar', width: 130 },
        { field: 'KartAdi', headerName: 'Stok Adı', width: 200 },
        {
            field: 'UretimOrani',
            headerName: 'Üretim Oranı',
            width: 130,
            renderCell: (params) => <SMProgress value={params.value} />,
        },
        {
            field: 'SevkiyatOrani',
            headerName: 'Sevkiyat Oranı',
            width: 130,
            renderCell: (params) => <SMProgress value={params.value} />,
        },
        { field: 'DepoAdi', headerName: 'Depo', width: 150 },
        { field: 'Miktar', headerName: 'Miktar', width: 100 },
        { field: 'BirimAdi', headerName: 'Birim', width: 100 },
        {
            field: 'BirimFiyatNet',
            headerName: 'Birim Fiyat',
            width: 130,
            valueFormatter: (params) =>
                new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: 'TRY',
                })
                    .format(params.value)
                    .replace('TRY', '₺'),
        },
        {
            field: 'Tutar',
            headerName: 'Tutar',
            width: 130,
            valueFormatter: (params) =>
                new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: 'TRY',
                })
                    .format(params.value)
                    .replace('TRY', '₺'),
        },
        {
            field: 'TeslimTarihi',
            headerName: 'Teslim Tarihi',
            width: 130,
            valueFormatter: (params) => new Date(params.value).toLocaleDateString('tr-TR'),
        },
        { field: 'Aciklama', headerName: 'Açıklama', width: 200 },
        { field: 'OlsAdi', headerName: 'Oluşturan', width: 100 },
        { field: 'OlsTar', headerName: 'Oluşturma Tarihi', width: 100 },
        { field: 'DgsAdi', headerName: 'DGS', width: 100 },
        { field: 'DgsTar', headerName: 'DGS Tarihi', width: 100 },
    ];

    const getDetailPanelContent = React.useCallback(
        ({ row }) => {
            const detailRows =
                orderMovementsQuery.data
                    ?.filter((movement) => movement.DekontID === row.id)
                    .map((movement) => {
                        const detailedInfo = ordersDetailedQuery.data?.find(
                            (detail) => detail.HareketID === movement.HareketID
                        );
                        return {
                            ...movement,
                            UretimOrani: detailedInfo?.UretimOrani || 0,
                            SevkiyatOrani: detailedInfo?.SevkiyatOrani || 0,
                        };
                    }) || [];

            return (
                <DataGridPro
                    rows={detailRows}
                    columns={detailColumns}
                    autoHeight
                    getRowId={(row) => row.HareketID}
                    hideFooter
                    loading={ordersDetailedQuery.isLoading}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            padding: '8px',
                        },
                    }}
                />
            );
        },
        [orderMovementsQuery.data, ordersDetailedQuery.data]
    );

    if (orderMovementsQuery.isError || ordersDetailedQuery.isError) {
        return (
            <Typography color="error">
                Hata: {orderMovementsQuery.error?.message || ordersDetailedQuery.error?.message}
            </Typography>
        );
    }

    return (
        <MDBox>
            <DataGridPro
                columns={orderColumns}
                rows={orderRowValues}
                autoHeight
                getRowId={(row) => row.id}
                loading={orderDetailsQuery.isLoading}
                sx={{
                    '& .MuiDataGrid-cell': {
                        padding: '8px',
                    },
                    '& .MuiTypography-root': {
                        fontWeight: 'normal',
                    },
                }}
                initialState={{
                    pinnedColumns: {
                        left: ['olusmaTarihi'],
                        right: ['projeDurumu'],
                    },
                }}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={() => 'auto'}
                rowThreshold={0}
                components={{
                    Footer: CustomFooter,
                }}
                componentsProps={{
                    footer: { rows: orderRowValues },
                }}
            />
        </MDBox>
    );
}

const ProjectButtons = memo(({ name, hareketId }) => (
    <a
        href={`https://erp2.aaro.com.tr/AlinanSiparisListe/Kalem?id=${hareketId}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
    >
        <Chip label={name} color="default" variant="outlined" clickable />
    </a>
));

const SituationButtons = memo(({ OnayDurum, HareketID }) => {
    const onayColor = (OnayDurum) => {
        switch (OnayDurum) {
            case 1:
                return { color: 'success', text: 'Onaylandı', icon: 'check_circle' };
            case 0:
                return { color: 'info', text: 'BEKLİYOR', icon: 'info' };
            case -1:
                return { color: 'error', text: 'RET!', icon: 'error' };
            default:
                return { color: 'default', text: 'Bilinmiyor', icon: 'help' };
        }
    };
    const { color, text, icon } = onayColor(OnayDurum);

    return (
        <a
            href={`https://erp2.aaro.com.tr/AlinanSiparisListe/Kalem?id=${HareketID}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
        >
            <Chip label={text} color={color} icon={<Icon>{icon}</Icon>} variant="outlined" clickable />
        </a>
    );
});

export default OrderMovements;
