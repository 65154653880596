// Material Dashboard 2 React Examples
import React, { useState, useMemo } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import MDBox from 'components/MDBox';
import DiffViewer from './DiffViewer';
import { IconButton, Dialog, DialogContent, DialogTitle } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const HistoryTimeline = ({ currentItems, pastItems }) => {
    const [openDiff, setOpenDiff] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const currentItem = currentItems[0];

    const handleDetailClick = (item) => {
        setSelectedItem(item);
        setOpenDiff(true);
    };

    const columns = useMemo(() => {
        // Get all possible keys from both current and past items
        const allKeys = new Set([...Object.keys(currentItem), ...pastItems.flatMap((item) => Object.keys(item))]);

        return [
            {
                field: 'actions',
                headerName: 'Actions',
                width: 100,
                renderCell: (params) => (
                    <IconButton onClick={() => handleDetailClick(params.row)}>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
            {
                field: 'date',
                headerName: 'Date',
                width: 180,
                valueFormatter: (params) => format(new Date(params.value), 'dd MMM yyyy HH:mm'),
            },
            {
                field: 'modifiedBy',
                headerName: 'Modified By',
                width: 150,
            },
            ...Array.from(allKeys)
                .filter((key) => !['date', 'modifiedBy', 'id'].includes(key))
                .map((key) => ({
                    field: key,
                    headerName: key.charAt(0).toUpperCase() + key.slice(1),
                    width: 150,
                    cellClassName: (params) => {
                        if (params.row.isCurrent) return '';
                        return params.value !== currentItem[key] ? 'changed-cell' : '';
                    },
                })),
        ];
    }, [currentItem, pastItems]);

    const rows = useMemo(() => {
        return [
            { ...currentItem, id: 'current', isCurrent: true },
            ...pastItems.map((item, index) => ({
                ...item,
                id: index,
                isCurrent: false,
            })),
        ];
    }, [currentItem, pastItems]);

    return (
        <MDBox width="100%" height="100%" p={2}>
            <DataGridPro
                rows={rows}
                columns={columns}
                autoHeight
                disableRowSelectionOnClick
                sx={{
                    '& .changed-cell': {
                        backgroundColor: 'rgba(76, 175, 80, 0.1)',
                    },
                }}
            />

            <Dialog open={openDiff} onClose={() => setOpenDiff(false)} maxWidth="md" fullWidth>
                <DialogTitle>Versiyon Kıyaslaması</DialogTitle>
                <DialogContent>
                    {selectedItem && (
                        <DiffViewer
                            oldCode={JSON.stringify(
                                Object.fromEntries(
                                    Object.entries(selectedItem).filter(
                                        ([key]) => !['date', 'id', 'isCurrent'].includes(key)
                                    )
                                )
                            )}
                            newCode={JSON.stringify(
                                Object.fromEntries(
                                    Object.entries(currentItem).filter(
                                        ([key]) => !['date', 'id', 'isCurrent'].includes(key)
                                    )
                                )
                            )}
                            open={true}
                            type="update"
                        />
                    )}
                </DialogContent>
            </Dialog>
        </MDBox>
    );
};

export default HistoryTimeline;
