import MDBox from 'components/MDBox';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import ErrorMessage from 'components/Common/ErrorMessage';
import GeneralSummary from './components/GeneralSummary';
import TimePassedCard from './components/TimePassedCard';
import Notes from 'layouts/orders/pages/SingleOrder/Sections/OrderSummary/components/Notes';
import FileViewGoogleDrive from 'components/General/File/FileViewGoogleDrive';
import AaroTodo from 'components/Aaro/Todo';

function OrderSummary({ queries, shadow = true, orderID }) {
    const { orderDetailsQuery, orderItemsQuery, notesQuery } = queries;
    if (orderDetailsQuery?.isLoading || orderItemsQuery?.isLoading || notesQuery?.isLoading) {
        return <LoadingSpinner />;
    }

    if (orderDetailsQuery?.error || orderItemsQuery?.error || notesQuery?.error) {
        return <ErrorMessage error={orderDetailsQuery?.error || orderItemsQuery?.error || notesQuery?.error} />;
    }

    const orderDetails = orderDetailsQuery?.data?.[0] || {};
    const orderProducts = orderItemsQuery?.data || [];
    const notes = notesQuery?.data || [];
    return (
        <MDBox>
            <TimePassedCard date={orderDetails?.OlsTar} />
            <GeneralSummary orderDetails={orderDetails} orderProducts={orderProducts} shadow={shadow} />
            <MDBox mt={3}>
                <FileViewGoogleDrive
                    folderId={'0AGRzb_BAuQn4Uk9PVA'}
                    getOrCreate={true}
                    getOrCreateFolderName={orderID}
                    documents={['Nihai Üretim Dosyaları', 'CNC Çizimleri']}
                />
            </MDBox>
            <Notes notes={notes} />
            <MDBox mt={3}>
                <AaroTodo params={{ TabloSatirID: orderID }} />
            </MDBox>
        </MDBox>
    );
}

export default OrderSummary;
