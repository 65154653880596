import * as React from 'react';
import { useQuery } from 'react-query';
import { getFilesGoogleDrive, getOrCreateFilesGoogleDrive } from 'services/api/general/file';
import { formatDateMinutes } from 'helpers/smallCodes';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

const getFileIcon = (mimeType) => {
    if (mimeType === 'application/vnd.google-apps.folder') return 'folder';
    switch (mimeType?.split('/')[1]) {
        case 'pdf':
            return 'picture_as_pdf';
        case 'msword':
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'description';
        case 'vnd.ms-excel':
        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'table_view';
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'gif':
            return 'image';
        default:
            return 'insert_drive_file';
    }
};

const getFileColor = (mimeType, name) => {
    if (mimeType === 'application/vnd.google-apps.folder') {
        switch (name) {
            case 'Fiyat Çalışmaları':
                return 'success';
            case 'Gönderilen Teklifler':
            case 'İlk Yüklemeler':
                return 'info';
            case 'Üretim':
                return 'warning';
            default:
                return 'secondary';
        }
    }

    switch (mimeType?.split('/')[1]) {
        case 'pdf':
            return 'error';
        case 'msword':
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'primary';
        case 'vnd.ms-excel':
            return 'success';
        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'success';
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'gif':
            return 'secondary';
        default:
            return 'secondary';
    }
};

const formatFileName = (name, mimeType) => {
    const specialFolders = ['Fiyat Çalışmaları', 'Gönderilen Teklifler', 'İlk Yüklemeler', 'Üretim'];

    if (mimeType === 'application/vnd.google-apps.folder' && specialFolders.includes(name)) {
        return name;
    }

    return name.toLowerCase();
};

function FileItem({ item, isLast }) {
    const color = getFileColor(item.mimeType, item.name);
    const icon = getFileIcon(item.mimeType);
    const isFolder = item.mimeType === 'application/vnd.google-apps.folder';
    const displayName = formatFileName(item.name, item.mimeType);

    return (
        <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="lg"
            py={1}
            pr={2}
            mb={isLast ? 0 : 1}
            sx={{ cursor: 'pointer' }}
            onClick={() => window.open(item.webViewLink, '_blank')}
        >
            <MDBox display="flex" alignItems="center">
                <MDBox
                    display="grid"
                    alignItems="center"
                    justifyContent="center"
                    bgColor={color}
                    borderRadius="lg"
                    shadow="md"
                    color="white"
                    width="40px"
                    height="40px"
                    mr={2}
                    variant="gradient"
                    fontSize="0.875rem"
                    sx={{
                        minWidth: '40px',
                        minHeight: '40px',
                        flexShrink: 0,
                    }}
                >
                    <Icon sx={{ fontSize: '20px' }}>{icon}</Icon>
                </MDBox>
                <MDBox display="flex" flexDirection="column">
                    <MDTypography variant="button" color={color} fontWeight="medium" gutterBottom>
                        {displayName}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                        {formatDateMinutes(item.createdAt)}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex">{isFolder && <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>}</MDBox>
        </MDBox>
    );
}

export default function FileViewGoogleDrive({
    folderId,
    refreshTrigger = 0,
    getOrCreate = false,
    getOrCreateFolderName = '',
    documents = [],
}) {
    const { data, error, isLoading, refetch } = useQuery(['googleDriveFiles', folderId], () =>
        getOrCreate
            ? getOrCreateFilesGoogleDrive(folderId, getOrCreateFolderName, documents)
            : getFilesGoogleDrive(folderId)
    );
    console.log(getOrCreate);
    React.useEffect(() => {
        refetch();
    }, [refreshTrigger, refetch]);

    if (isLoading) return <div>Yükleniyor...</div>;
    if (error) return <div>Hata oluştu</div>;
    if (!data?.data?.result) return <div>Dosya bulunamadı</div>;

    const folders = [...data.data.result]
        .filter((item) => item.mimeType === 'application/vnd.google-apps.folder')
        .sort((a, b) => {
            const folderOrder = {
                'Fiyat Çalışmaları': 1,
                'Gönderilen Teklifler': 2,
                'İlk Yüklemeler': 3,
                Üretim: 4,
            };
            const orderA = folderOrder[a.name] || 999;
            const orderB = folderOrder[b.name] || 999;
            return orderA - orderB;
        });

    const files = [...data.data.result]
        .filter((item) => item.mimeType !== 'application/vnd.google-apps.folder')
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <Card>
            <MDBox pt={2} px={2}>
                <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                    sx={{
                        cursor: 'pointer',
                        '&:hover': { opacity: 0.8 },
                    }}
                    onClick={() => {
                        if (data?.data?.mainFolderId) {
                            window.open(`https://drive.google.com/drive/folders/${data.data.mainFolderId}`, '_blank');
                        }
                    }}
                >
                    Google Drive
                </MDTypography>
            </MDBox>
            <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {folders.map((folder, index) => (
                        <FileItem key={folder.id} item={folder} isLast={index === folders.length - 1} />
                    ))}
                    {folders.length > 0 && files.length > 0 && (
                        <MDBox my={2}>
                            <Divider />
                        </MDBox>
                    )}
                    {files.map((file, index) => (
                        <FileItem key={file.id} item={file} isLast={index === files.length - 1} />
                    ))}
                </MDBox>
            </MDBox>
        </Card>
    );
}
