import Cookies from 'js-cookie';
import axios from 'axios';
import { Token } from 'aaro';
import { config } from 'Constants';
import { refreshGoogleToken } from 'services/api/user';

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log('Interceptor triggered with error:', error.response?.status);
        const originalRequest = error.config;

        // Maksimum tekrar deneme sayısı
        originalRequest.retryCount = originalRequest.retryCount || 0;
        const MAX_RETRY_COUNT = 3;

        // 401 hatası ve tekrar deneme kontrolü
        if (error.response?.status === 401 && originalRequest.retryCount < MAX_RETRY_COUNT) {
            originalRequest.retryCount += 1;

            const user = JSON.parse(localStorage.getItem('user')) || {};
            console.log('Current user from localStorage:', user);

            // Google token yenileme
            if (user.googleAuth?.refreshToken) {
                const expiryDate = new Date(user.googleAuth.expiryDate);
                const now = new Date();
                console.log('Google Auth Status:', {
                    expiryDate,
                    now,
                    isExpired: now >= expiryDate,
                });

                if (now >= expiryDate) {
                    try {
                        console.log('Attempting to refresh Google token...');
                        const response = await axios.post('/api/users/auth/google/refresh', null, {
                            headers: {
                                Authorization: `Bearer ${user.token}`,
                            },
                        });
                        console.log('Refresh token response:', response.data);

                        if (response.data.success) {
                            console.log('Token refresh successful, updating user...');
                            const updatedUser = {
                                ...user,
                                googleAuth: {
                                    accessToken: response.data.result.accessToken,
                                    refreshToken: user.googleAuth.refreshToken,
                                    expiryDate: response.data.result.expiryDate,
                                },
                            };
                            localStorage.setItem('user', JSON.stringify(updatedUser));

                            // Orijinal isteği yeni token ile tekrar dene
                            originalRequest.headers.Authorization = `Bearer ${response.data.result.accessToken}`;
                            return axios(originalRequest);
                        }
                    } catch (err) {
                        console.error('Google token refresh failed:', {
                            error: err,
                            response: err.response?.data,
                        });
                    }
                }
            }

            // Aaro token yenileme
            try {
                console.log('Attempting Aaro token refresh...');
                const url = config.url.AARO_BASE;
                if (user.token != null) {
                    const rp = await Token(url, Cookies.get('aaroUser'), Cookies.get('aaroPass'));
                    const newToken = rp.data.access_token;
                    const updatedUser = { ...user, aaroToken: newToken };
                    localStorage.setItem('user', JSON.stringify(updatedUser));
                    console.log('Aaro token refresh successful');

                    // Orijinal isteği yeni token ile tekrar dene
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axios(originalRequest);
                }
            } catch (err) {
                console.error('Aaro token refresh failed:', err);
            }

            // Genel token yenileme fallback
            if (user) {
                try {
                    const updatedUser = await refreshGoogleToken(user);
                    if (updatedUser && updatedUser !== user) {
                        localStorage.setItem('user', JSON.stringify(updatedUser));

                        // Orijinal isteği yeni token ile tekrar dene
                        originalRequest.headers.Authorization = `Bearer ${updatedUser.token}`;
                        return axios(originalRequest);
                    }
                } catch (err) {
                    console.error('Fallback token refresh failed:', err);
                }
            }
        }

        return Promise.reject(error.response?.data || error);
    }
);

export default axios;
