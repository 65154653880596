import { createContext, useState, memo, useEffect } from 'react';
// import aa from 'search-insights';

// import DisplayStocks from './DisplayStocks';
import Card from '@mui/material/Card';

import StockGrid from './views/stockGrid';
import BasketGrid from './views/basketGrid';
// import AlgoliaData from './views/algoliaData';
import AaroLayout from 'layouts/aaro/AaroLayout';
import DekontsTable from 'components/Aaro/Dekont/DekontsTable';
import MDBox from 'components/MDBox';
import { Algolia } from './views/algoliaView';

import { useMaterialUIController, setMiniSidenav } from 'context';

export const BasketContext = createContext();

// AHS.MTFK.00005
function FastPrice() {
    const [controller, dispatch] = useMaterialUIController();

    useEffect(() => {
        setMiniSidenav(dispatch, true);
    }, []);

    const useLocalStorage = (key, initialValue) => {
        const [storedValue, setStoredValue] = useState(() => {
            try {
                const item = localStorage.getItem(key);
                return item ? JSON.parse(item) : initialValue;
            } catch (error) {
                console.error(error);
                return initialValue;
            }
        });

        const setValue = (value) => {
            try {
                setStoredValue(value);
                localStorage.setItem(key, JSON.stringify(value));
            } catch (error) {
                console.error(error);
            }
        };

        return [storedValue, setValue];
    };

    const [basket, setBasket] = useLocalStorage('basket', []);
    const [activeView, setActiveView] = useState('algolia');
    const aaroKullanici = JSON.parse(localStorage.getItem('AaroKullanici'));

    return (
        <BasketContext.Provider value={{ basket, setBasket, setActiveView }}>
            <AaroLayout setActiveView>
                {activeView === 'algolia' ? (
                    <>
                        {basket.length > 0 && (
                            <MDBox m={3}>
                                <BasketGrid />{' '}
                            </MDBox>
                        )}
                        <Algolia />
                        {/* <AlgoliaData /> */}
                    </>
                ) : (
                    <Card>
                        {activeView === 'fiyat' ? (
                            <>
                                <StockGrid />
                                {basket.length > 0 && <BasketGrid />}
                            </>
                        ) : (
                            <DekontsTable
                                params={{
                                    TipID: '10005,10016',
                                    SiralamaKisiti: 'DgsTar:Desc',
                                    OlsID: aaroKullanici.KulID ? aaroKullanici.KulID : -1,
                                }}
                                size="small"
                            />
                        )}
                    </Card>
                )}
            </AaroLayout>
        </BasketContext.Provider>
    );
}

export default memo(FastPrice);

// {
// DepoID: aaroKullanici.DepoID ? aaroKullanici.DepoID : 11,
// // StokKodu: 'DRL.03.00001',
// StokKodu: 'PRF.KASA.01046,DRL.03.00001',
// }
